<section id="portfolio" class="portfolio">
  <div class="container">
      <h3 class="sub-title border-bot-light animate__animated animate__fadeInLeft"><strong>Gallery</strong></h3>
    <div
      class="row portfolio-container">
      <div
        *ngFor="let item of gallery"
        class="col-lg-3 col-md-6 portfolio-item">
        <div class="portfolio-wrap rounded-xl ">
          <img [src]="item.imageUrl" class="img-fluid animate__animated animate__zoomIn" alt="image" />
          <div class="portfolio-info">
            <div class="portfolio-links">
              <a href=""
                data-gallery="portfolioGallery"
                class="portfolio-lightbox"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container">
<div class="section-title">
  <h3 class="sub-title border-bot-light animate__animated animate__fadeInLeft"><strong>videos</strong></h3>
</div>

<div class="video-row animate__animated animate__fadeInUpBig" style="animation-duration: 3s;">
  <youtube-player [videoId]="videoId" [height]="220" [width]="400" suggestedQuality="hd720"></youtube-player>
  <youtube-player [videoId]="videoId1" [height]="220" [width]="400" suggestedQuality="hd720"></youtube-player>
  <youtube-player [videoId]="videoId2" [height]="220" [width]="400" suggestedQuality="hd720"></youtube-player>
</div>

<div class="video-row1 animate__animated animate__fadeInDown" style="animation-duration: 3s;">
  <youtube-player [videoId]="videoId3" [height]="220" [width]="400" suggestedQuality="hd720"></youtube-player>
  <youtube-player [videoId]="videoId4" [height]="220" [width]="400" suggestedQuality="hd720"></youtube-player>
</div>
</div>
