import { style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sdpr',
  templateUrl: './sdpr.component.html',
  styleUrls: ['./sdpr.component.scss'],
})
export class SdprComponent implements OnInit {
  selectedItem: string = '';
  selectedImage: any;

  constructor(private route: ActivatedRoute, private router: Router) { }

  navigateTo(relativePath: string): void {
    this.router.navigate([relativePath], { skipLocationChange: true });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.selectedItem = params['itemName'];
      this.selectItem(this.selectedItem);
    });
  }

  selectItem(selectedItem: string) {
    console.log('Selected Item:', selectedItem);

    switch (selectedItem) {
      case 'basis-of-design':
        this.selectedImage =
          '../../../../assets/GOVT-services/Basis Of Design.jpg'
        break;
      case 'Building-Design-Phase':
        this.selectedImage =
          '../../../../assets/Services-Images/Building Design Phase.jpg';
        break;
      case 'Land-Selection':
        this.selectedImage =
          '../../../../assets//Services-Images/Land Selection FoodCognics.jpg';
        break;
      case 'Machinery-Selection':
        this.selectedImage =
          "assets/Services-Images/Machinery selection ' FoodCognics.jpg";
        break;
      case 'Architectural-Service':
        this.selectedImage =
          '../../../../assets/Services-Images/Architectural Services.jpg';
        break;
      case 'Vendor-Selection-&-Management':
        this.selectedImage =
          '../../../../assets/Services-Images/Vendor Selection and management.jpg';
        break;
      case 'Site-supervision':
        this.selectedImage =
          '../../../../assets/Services-Images/Site Supervision FoodCognics.jpg';
        break;
      case 'Preparation-Of-progress-report':
        this.selectedImage =
          '../../../../assets/Services-Images/Progress Report.jpg';
        break;
      case 'Value-Engineering':
        this.selectedImage =
          '../../../../assets/Services-Images/Value Engineering.jpg';
        break;
      case 'Master-Project-Budget-preparation':
        this.selectedImage =
          '../../../../assets/Services-Images/Master Project Budget Preparation.jpg';
        break;
      case 'Preparation-of-Implementation-Schedule':
        this.selectedImage =
          '../../../../assets/GOVT-services/Implementation Schedule.jpg';
        break;
      case 'Documents-&-Handover-Procedures':
        this.selectedImage =
          '../../../../assets/Services-Images/Documents and handover Procedure.jpg';
        break;
      default:
        this.selectedImage = '';
        break;
    }
  }
}
