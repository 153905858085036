<div *ngIf="selectedItem === 'FSSAI'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown" src="../../../assets/about/License FoodCognics.jpg"
      alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>FSSAI</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              FSSAI license services help businesses acquire the Food Safety and Standards Authority of India (FSSAI)
              license.
              This license is mandatory for all food businesses in India. The type of license you need depends on your
              business's annual turnover. These services can assist with the application process, ensuring it's
              completed accurately and efficiently.
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Benefits of procuring FSSAI license:</p>
            <p>
              1. Legal compliance <br />
              2. Consumer trust <br />
              3. Expansion opportunities <br />
              4. Access to information <br />
              5. Brand reputation <br />
              6. Reduced risk
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Types of License:</p>
            <br />
            <p>
              1. State-Turnover in between 12 lakh to 20 crores<br />
              2. Central- Turnover more than 20 crores<br />
              3. Registration- Turnover less than 12 lakh
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Documents required:</p>
            <br />
            <p>
              1. layout plan of the processing unit <br />
              2. List of Directors <br />
              3. Name and List of Equipment and Machinery <br />
              4. Photo I.D and address proof.<br />
              5. Form IX<br />
              6. Board of resolution<br />
              7. Water analysis report<br />
              8. Proof of possession of premises<br />
              9. Partnership Deed/Affidavit/Memorandum & Articles of
              Association<br />
              towards the constitution of the firm.<br />
              10. Food Safety Management System plan or certificate<br />
              11. Recall plan<br />
              12. NOCs from Municipality or local body
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Registration process for FSSAI license</p>
            <br />
            <p>
              Step 1: A login is to be created first in the foscos website
              <br />
              Step 2: Fill the required details along with necessary
              documents.<br />
              Step 3: Make the payment of registration fee.<br />
              Step 4: Documents scrutination by food authority<br />
              Step 5: Application get approved and generate the fssai license
              no.
            </p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/Services-Images/fssai.png" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'HALAL'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/about/Certification FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>HALAL Certification</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              A Halal certification is a guarantee that the food is prepared
              adhering to the Islamic law and is unadulterated. It is primarily
              used on meat products as well as other food items including milk,
              meals in cans, and additives. In particular, Halal certifies that
              for meat products, the animals were killed in a single cut, fully
              bled, and their meat was not in contact with any other animals
              that were slaughtered, particularly those that were pork products.
              Halal-certified products are frequently identified with a Halal
              symbols or just the letter M (as kosher products are identified
              with the letter K for the Jewish people).Many private companies in
              India provide halal certification, designating food or products
              that are acceptable for consumption by Muslims. While the Indian
              government recognizes some of these halal certifying agencies, it
              does not acknowledge others. Halal certification was first
              introduced in 1974 for slaughtered meat as no record of halal
              certification is found before that. Halal meat meant the meat
              which has been procured through the process permitted in Islam.
              The animal has to be slaughtered through the throat, oesophagus,
              and jugular veins but not the spinal cord.
            </p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/Services-Images/halal.png" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'USFDA'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/about/Certification FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>USFDA</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              The U.S. Food and Drug Administration (FDA) is a federal agency
              within the United States Department of Health and Human Services
              (HHS). Its primary role is to protect and promote public health by
              controlling and supervising the safety of foods, dietary
              supplements, prescription and over-the-counter medications,
              vaccines, biopharmaceuticals, blood transfusions,
              radiation-emitting devices, veterinary products, and more.
            </p>
            <br />
            <p>
              The FDA regulates a diverse range of products, setting and
              enforcing standards for safety, efficacy, and quality in
              manufacturing, distribution, and marketing. It reviews and
              approves drugs, evaluates clinical trial data, monitors and
              enforces food safety regulations, oversees biopharmaceuticals and
              vaccines, regulates medical devices, tobacco products, cosmetics,
              and dietary supplements
            </p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/Services-Images/usfda.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Kosher'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/about/Certification FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">

      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>KOSHER</strong>
          </h1>

          <div class="animate__animated animate__backInRight">
          </div>

          <p>
            Kosher certification in the food industry involves ensuring that
            food products comply with Jewish dietary laws (kashrut). Products
            meeting these standards receive a "kosher" label. Certification is
            granted by a rabbinic authority, and compliance may involve
            specific processing methods, ingredient checks, and facility
            inspections. Kosher certification enhances market appeal for a
            diverse consumer base, including those adhering to Jewish dietary
            practices, and signifies adherence to religious guidelines in food
            production.
          </p><br />

          <p>
            <b>F. Kosher:</b><br />
            Kosher certification in the food industry involves ensuring that food products comply with
            Jewish dietary laws (kashrut). Products meeting these standards receive a "kosher" label. Certification is
            granted by a rabbinic authority, and compliance may involve specific processing methods, ingredient
            checks, and facility inspections. Kosher certification enhances market appeal for a diverse consumer base,
            including those adhering to Jewish dietary practices, and signifies adherence to religious guidelines in
            food production.<br />
            <b>● Following Tradition:</b>Observant Jews consume only food following these religious guidelines.<br />
            <b>● Focus on Animals: </b>Only specific animals are allowed, and they must be slaughtered in a prescribed
            way.<br />
            <b>● Meat & Dairy Separate:</b> Meat and dairy products cannot be consumed or cooked together.<br />
            <b>● Supervision Needed: </b>Kosher certification ensures compliance by a trained Rabbi.<br />
            <b> ● Beyond Food:</b> Kosher applies to ingredientsin processed foods, wines, and even some
            medications.<br />
            <b> ● Global Observance:</b> Millions follow Kosher worldwide, creating a significant market for certified
            products.<br />
            <b> ● Symbol of Quality:</b> Kosher certification can also signify high-quality ingredients and production
            processes.<br />
            <b> ● Detailed Rules:</b> The specifics of Kosher laws are vast and complex, overseen by Rabbinical
            authorities. <br />
            <b>● Personal Choice:</b> Beyond religious observance, some choose Kosher for dietary or ethical
            reasons.<br />
            <b> ● Growing Market:</b> With rising health consciousness, Kosher food is gaining wider appeal.<br />
          </p>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/about/kosher.webp" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'HALAL'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/about/Certification FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>HALAL Certification</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              The U.S. Food and Drug Administration (FDA) is a federal agency
              within the United States Department of Health and Human Services
              (HHS). Its primary role is to protect and promote public health by
              controlling and supervising the safety of foods, dietary
              supplements, prescription and over-the-counter medications,
              vaccines, biopharmaceuticals, blood transfusions,
              radiation-emitting devices, veterinary products, and more
            </p>
            <br />
            <p>
              The FDA regulates a diverse range of products, setting and
              enforcing standards for safety, efficacy, and quality in
              manufacturing, distribution, and marketing. It reviews and
              approves drugs, evaluates clinical trial data, monitors and
              enforces food safety regulations, oversees biopharmaceuticals and
              vaccines, regulates medical devices, tobacco products, cosmetics,
              and dietary supplements.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Legal-Metrology'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown" src="../../../assets/about/License FoodCognics.jpg"
      alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Legal Metrology</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <i class='bx bxs-hand-right'></i>
            <p>Manufacturing</p>
            <i class='bx bxs-hand-right'></i>
            <p>Relabelling</p>
            <i class='bx bxs-hand-right'></i>
            <p>Repacker</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'BRC'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/about/Certification FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>BRC Certification</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              The BRC (British Retail Consortium) Global Standards is a leading
              global safety and quality certification program for food and other
              consumer products. It provides a framework for best practices in
              food safety, quality, and operational criteria, ensuring
              compliance with legal obligations and protection of consumers.
              Here's an overview of BRC in relation to the food industry:
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Certification Program:</p>
            <br />
            <p>
              BRC Global Standards offer a certification program that covers
              various aspects of the food supply chain, including production,
              packaging, storage, and distribution.
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Scope:</p>
            <br />
            <p>
              BRC standards are applicable to different sectors within the food
              industry, such as food manufacturers, packaging manufacturers,
              storage and distribution facilities, and retailers.
            </p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/Services-Images/brc.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'BIS'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/about/Certification FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>BIS certification</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              the Bureau of Indian Standards (BIS) plays a crucial role in
              setting standards for various products, including those in the
              food industry. Please note that regulations may be subject to
              change, and it's essential to refer to the latest guidelines and
              updates from BIS or other relevant authorities
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Mandatory BIS Certification:</p>
            <br />
            <p>
              BIS may mandate certification for certain food products to ensure
              they meet defined quality and safety standards.
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>ISI Mark:</p>
            <br />
            <p>
              The ISI (Indian Standards Institute) mark or BIS mark on food
              products indicates compliance with the specified standards.
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Food Safety Standards:</p>
            <br />
            <p>
              BIS establishes and maintains standards for various food products
              to ensure their safety, quality, and conformity to regulatory
              requirements.
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Packaged Drinking Water and Packaged Natural Mineral Water:</p>
            <br />
            <p>
              BIS sets standards for packaged drinking water and packaged
              natural mineral water to ensure quality and safety.
            </p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/Services-Images/bis.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Vegan-Product-Approved-By-FSSAI'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown" src="../../../assets/about/License FoodCognics.jpg"
      alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Vegan food approval by FSSAI</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              1. Vegan food refers to ingredients in food, including additives, flavourings, enzymes, carriers, and
              processing aids, that are not animal-derived and have not been used in production or processing.. They
              exclude meat, dairy, eggs, and other animal products.<br />

              2. Common vegan foods include fruits, vegetables, grains, legumes, nuts, seeds, and plant-based
              alternatives like tofu, tempeh, and plant-based milk

            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Application process</p><br />
            <p>
              1. Fill out and submit Form A along with the requisite fees.<br />
              2. The food authority scrutinises the application.<br />
              3. The food authority either approves or rejects the
              application.<br />
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Documents required:</p><br />
            <p>
              1. Product name<br />
              2. Ingredients<br />
              3. Detailed process flow sheet<br />
              4. Certificate of analysis (CoA) of each ingredient from
              supplier<br />
              5. Label copy<br />
              6. Final product COA from FSSAI notified lab<br />
              7. Self-declaration form<br />
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Fee</p>
            <br />
            <p>25000 Rs + GST</p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/about/VEGAN.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Land-Acquisition'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown" src="../../../assets/about/License FoodCognics.jpg"
      alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Land Acquisition</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              land acquisition services are not directly provided by food
              consultancy firms. Food consultancy services primarily focus o
              advising and assisting businesses in the food industry with
              aspects related to production, product development, market
              strategies, regulatory compliance, and other operational areas.
            </p>
            <br />
            <p>
              Land acquisition is a specific area that involves purchasing or
              leasing land for various purposes, such as setting up
              manufacturing facilities, farms, or distribution centers. While
              food consultancy services may guide companies on the overall
              expansion strategy, including the need for additional land, the
              actual process of acquiring land involves legal, real estate, and
              logistical considerations that often fall outside the scope of
              traditional food consultancy services.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'FSSC-Certification'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/about/Certification FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>FSSC Certification</strong><br />
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              A comprehensive food safety management system certification
              scheme, FSSC (also known as FSSC 22000) is built upon three
              current certifications: ISO 22000, ISO 22002-1 (a precondition for
              food processing), and ISO 22002-4 (a prerequisite for package
              manufacturing).
            </p>
            <br />
            <p>
              FSSC is a certification scheme that is benchmarked by the Global
              Food Safety Initiative (GFSI) and is suitable for food companies
              who prioritise scientific and technological advancements, as well
              as those who operate worldwide. Large organisations may also find
              FSSC intriguing because, being ISO 17021 based, it permits site
              sampling, which could result in cost savings for a large multisite
              business.
            </p>
            <br />
            <p>
              Moving from ISO 22000 to FSSC 22000 can be a fairly easy procedure
              for firms that are already certified, and it doesn't require
              expensive or time-consuming assessments. It is possible to combine
              an approaching ISO 22000 audit with the FSSC 22000 transition
              audit through an FSSC licensed certification organization, like
              PJR!
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Why become FSSC 22000 certified?</p>
            <br />
            <p>
              1. The FSSC Foundation is independent, meaning that FSSC 22000 is
              managed free of outside influences. <br />
              2. Internationally accepted by food buyers worldwide. <br />
              3. GFSI benchmarking may fulfill additional buyers’ requirements;
              GFSI benchmarks are among the highest, most stringent and
              up-to-date set of standards globally. <br />
              4. Applying a management system aimed at both internal and
              external food safety risks may give a competitive advantage.
              <br />
              5. Certification to a food safety management system may help
              develop a more rigorous internal food safety culture. <br />

            </p>
            <br /><br />

            <i class='bx bxs-hand-right'></i>
            <p>Enroll / Application</p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/about/FSSC.png" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'ISO-Certification'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/about/Certification FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>ISO Certification</strong><br />
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              ISO 9001 is the internationally recognized standard for Quality Management Systems (QMS). It's a framework
              that helps organizations of all sizes and industries demonstrate their ability to consistently deliver
              products and services that meet customer and regulatory requirements.
            </p>
            <br />
            <p>
              Here are some key points about ISO 9001:<br />
              <b> ● Focus on customer satisfaction:</b> The standard emphasizes understanding customer needs and
              exceeding their
              expectations.<br />
              <b> ● Process-oriented approach:</b> It requires organizations to define and document their key
              processes, ensuring consistency and efficiency.<br />
              <b> ● Continuous improvement: </b>A core principle of ISO 9001 is the concept of continual improvement,
              encouraging organizations to constantly strive to be better.<br />
              <b>● Benefits:</b> Implementing ISO 9001 can lead to
              improved customer satisfaction, increased efficiency, reduced costs, and enhanced competitiveness.

            </p>
            <br />
            <p>
              E.Halal Certificate:<br />
              Halal certification refers to a formal recognition that a product or service
              complies with Islamic law (Sharia) principles. It assures Muslim consumers that a product or service is
              permissible for consumption or use. This includes adhering to guidelines on ingredients, slaughter
              methods, and overall production processes.<br />
              <b>● Purpose: </b>It assures Muslim consumers that a product or service is permissible for consumption or
              use.
              This includes adhering to guidelines on ingredients, slaughter methods, and overall production
              processes.<br />
              <b> ● Products requiring certification:</b> Food and beverages are the most common examples, but
              cosmetics,
              pharmaceuticals, and even services like halal tourism can also be certified.<br />
              <b> ● Certification bodies: </b>Recognized halal certification bodies assess and verify compliance with
              halal
              standards. These bodies may
              be governmental or non-governmental organizations.<br />
              <b> ● Benefits: </b>For businesses, halal certification opens
              doors to the vast Muslim consumer market and demonstrates a commitment to ethical production. For
              consumers, it provides peace of mind when purchasing products that align with their religious beliefs.
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Application process:</p>
            <br />
            <p>
              1. Application form <br />
              2. Prepare documentations and manuals <br />
              3. Conduct audit <br />
              4. Generate a audit report<br />
            </p>
            <br /><br />
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Enroll / Application</p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/about/ISO.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'licensingRegulatoryServices'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown" src="../../../../assets/sector/DPR.jpg"
      alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Licensing & Regulatory services</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              In today's global landscape, Food Safety is a paramount and widely
              discussed topic. Numerous incidents have underscored the critical
              importance of stringent food safety measures, such as instances of
              food adulteration and misleading labeling practices, sometimes
              orchestrated to harm a business's reputation. Recognizing and
              complying with the extensive regulations governing the food
              industry has become not only a legal requirement but also a vital
              strategy for businesses aiming to thrive and succeed.
            </p>
            <p>
              In response to this, our expert council, comprising professionals
              with extensive experience at both national and international
              levels, is well-versed in the intricacies of licensing and
              regulatory services within the food industry. Their proficiency
              extends to guiding businesses through the complexities of
              compliance, helping them navigate the regulatory landscape, and
              ensuring adherence to the highest standards of food safety.
            </p>
            <p>
              By leveraging the expertise of our council, businesses can
              proactively address food safety challenges, mitigate risks, and
              build a robust foundation for sustained success within the dynamic
              and highly regulated food industry.
            </p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../../assets/sector/DPR.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'MPCB'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown" src="../../../assets/about/License FoodCognics.jpg"
      alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>MPCB</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            >
            <p>
              The Maharashtra Pollution Control Board (MPCB) is a government
              agency responsible for environmental protection and pollution
              control in the state of Maharashtra, India.
            </p>
            >
            <p>
              Established in 1970 under the Water (Prevention and Control of
              Pollution) Act, the MPCB's primary objective is to monitor and
              regulate various sources of pollution, including air, water, and
              noise pollution.
            </p>
            >
            <p>
              MPCB plays a crucial role in implementing and enforcing
              environmental laws and policies to safeguard the state's natural
              resources and public health.
            </p>
            >
            <p>
              The board carries out regular inspections of industries,
              commercial establishments, and construction projects to ensure
              compliance with environmental regulations.
            </p>
            >
            <p>
              It issues licenses and permits for the discharge of pollutants and
              sets emission standards for industries to maintain environmental
              quality.
            </p>
            >
            <p>Air Quality</p>
            >
            <p>Water Quality</p>
            >
            <p>Noise Quality</p>

            <i class='bx bxs-hand-right'></i>
            <p>Documents required:</p>
            <br />
            <p>
              1. Proof of possession of premises<br />
              2. Detailed proposal for pollution control system<br />
              3. Certificate of Incorporation<br />
              4. Manufacturing process<br />
              5. CA certificate, balance sheet, Capital investment<br />
            </p>
            <i class='bx bxs-hand-right'></i>
            <p>Application process</p>
            <br />
            <p>
              1. Register on online website https://www.ecmpcb.in/ <br />
              2. Fill the application form along with essential documents.<br />
              3. Pay the requisite fee and submit the application<br />
              4. Site visit by field officers<br />
              5. Application scrutinization<br />
              6. Certificate generated<br />
            </p>
            <i class='bx bxs-hand-right'></i>
            <p>Validity</p>
            <br />
            <p>5 years</p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/about/MPSC.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Spice-Board'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown" src="../../../assets/about/License FoodCognics.jpg"
      alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Spice Board</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              The Spice Board of India is a government organisation under the
              Ministry of Commerce and Industry. In both domestic and foreign
              markets, it is in charge of developing and promoting Indian
              spices. The board is crucial for maintaining the quality,
              security, and export of different spices made in India.
            </p>

            <i class='bx bxs-hand-right'></i>
            <p>Documents required:</p>
            <br />
            <p>
              1. Import Export Code<br />
              2. PAN Card<br />
              3. GST Certificate<br />
              4. Confidential bank Report showing the net worth of the
              company<br />
              5. If the company authorizes the applicant to file the
              application, then an authorization letter authorizing that
              applicant is also needed<br />
              6. Certificate of Incorporation/Partnership Deed/MOA – AOA<br />
              7. List of Directors/partners and their contact information.<br />
              8. MSME registration<br />
              9. FSSAI/ Pollution Control Board Certificate<br />
              10. Proof of possession<br />
            </p>
            <i class='bx bxs-hand-right'></i>
            <p>Application process</p>
            <br />
            <p>
              1. fill out application form <br />
              2. Upload all necessary documents<br />
              4. Application assessment<br />
              4. Pay registration fee<br />
              5. Premises inspection<br />
              6. Certificate generated<br />
            </p>
            <i class='bx bxs-hand-right'></i>
            <p>Validity</p>
            <br />
            <p>3 years</p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/about/spiceboard.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Organic-Certification'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/about/Certification FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Organic Certification</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              An organic food certificate is a label that confirms products
              adhere to organic farming standards and are free of artificial
              chemicals, pesticides, and genetically modified organisms (GMOs).
              The farms and producers are evaluated by certifying organisations
              to make sure they follow these guidelines, giving consumers
              confidence in the product's organic certification and quality
              controls.
            </p>

            <i class='bx bxs-hand-right'></i>
            <p>Documents required</p>
            <br />
            <p>
              An application can be prepared and submitted by the applicant once
              the standards for organic farming have been met. <br />
              1. Organic production system plan <br />
              2. Soil and water test report <br />
              3. 7/12 extract <br />
              4. Off farm input guaranty<br />
            </p>
            <i class='bx bxs-hand-right'></i>
            <p>Application process</p>
            <br />
            <p>
              In order to ensure that their farm satisfies the necessary
              standards, a person (farmer/processor/trader) seeking an organic
              farming certificate must adhere to NPOP's standards for organic
              crop production.<br />
              1. Submit application form along with fees.<br />
              2. Field verification as specified by NPOP<br />
              3. Review application form<br />
              4. Inspection visit<br />
              5. Issued of certificate
              <br />
            </p>

            <i class='bx bxs-hand-right'></i>
            <p>Validity</p>
            <br />
            <p>1 year. It will be renew after every year</p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Eligibility</p>
            <br />
            <p>
              Since two to three years, the product must be linked to
              authenticity in order to receive certification. For initial
              certification, the soil must satisfy the prerequisite that it has
              been free of the use of prohibited substances (synthetic
              chemicals, etc.) for a period of time. For this time, a
              conventional farm must adhere to organic standards.
            </p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/about/organics.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Legal-metrology-act'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown" src="../../../assets/about/License FoodCognics.jpg"
      alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Legal Metrology Act</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              The Legal Metrology Act regulates food product measurement and
              packaging, ensuring accurate and equitable sales. It protects
              customers from misleading practices and promotes transparency in
              food transactions by defining labelling, weight, and volume
              metrics. Food enterprises must abide by this law to provide
              accurate information and uphold standards.
            </p>

            <i class='bx bxs-hand-right'></i>
            <p>Documents required</p>
            <br />
            <p>
              1. SHop and establishment act <br />
              2. Pan card of company <br />
              3. List of director <br />
              4. MoA, AoA <br />
              5. FSSAI license
              <br />
            </p>
            <i class='bx bxs-hand-right'></i>
            <p>Application process</p>
            <br />
            <p>
              1. FIll an application form <br />
              2. Pay the requisite fees <br />
              3. Upload documents <br />
              4. Submit the application <br />
              5. Certificate issue
              <br />
            </p>

            <i class='bx bxs-hand-right'></i>
            <p>Validity</p>
            <br />
            <p>1 year. It will be renew after every year</p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/about/legalMetrologyImg.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'APEDA'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown" src="../../../assets/about/License FoodCognics.jpg"
      alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>APEDA</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              APEDA, the Agricultural and Processed Food Products Export
              Development Authority, is an autonomous organization established
              by the Indian government under the Ministry of Commerce and
              Industry. Its primary objective is to promote the export of
              agricultural and processed food products, providing services like
              market research, quality control, infrastructure development, and
              capacity building. APEDA also works on maintaining and improving
              product quality, adhering to international standards and
              regulations.
            </p>

            <i class='bx bxs-hand-right'></i>
            <p>Documents required</p>
            <br />
            <p>
              1. Import-export code document<br />
              2. Bank certificate<br />
              3. Firm’s Account statement for past 3 months<br />
              4. Cancelled cheque
              <br />
            </p>
            <i class='bx bxs-hand-right'></i>
            <p>Application process</p>
            <br />
            <p>
              1. Filling of application form <br />
              2. Upload all necessary documents<br />
              3. Pay the requisite fees<br />
              4. Submitting of application form
              <br />
            </p>

            <i class='bx bxs-hand-right'></i>
            <p>Validity</p>
            <br />
            <p>5 years</p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Fee</p>
            <br />
            <p>5000 + GST</p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/about/APEDA1.png" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Import-Export-certificate'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/about/Certification FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Import-Export certificate</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              IEC is a 10-digit code issued by a government for individuals or
              businesses involved in import and export activities. It
              facilitates customs clearance and helps track international trade
              transactions, with requirements and procedures varying across
              countries.
            </p>

            <i class='bx bxs-hand-right'></i>
            <p>Documents required</p>
            <br />
            <p>
              1. PAN Card of business organisation <br />
              2. Certificate of Incorporation <br />
              3. Bank account details of the organisation with cancelled cheque
              <br />
              4. Address proof of organisation <br />
              5. Identity proof and passport size photograph
              <br />
            </p>
            <i class='bx bxs-hand-right'></i>
            <p>Application process</p>
            <br />
            <p>
              1. Registration on Government portal <br />
              2. Filling of application form <br />
              3. Upload all essential documents <br />
              4. Submission of form <br />
              5. Verification by DGFT <br />
              6. Issuance of IEC code
              <br />
            </p>

            <i class='bx bxs-hand-right'></i>
            <p>Validity</p>
            <br />
            <p>1 year. It will renew after one year</p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Fee</p>
            <br />
            <p>500 Rs.</p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/about/IMPORT.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Factory-Act'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown" src="../../../assets/about/License FoodCognics.jpg"
      alt="Web Background" />
    <div class="overlay-container"></div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Factory Act</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              The Factories Act, 1948 (Act No. 63 of 1948), as amended by the
              Factories (Amendment) Act, 1987 (Act 20 of 1987), served to assist
              in formulating national policies in India with respect to<a>
                occupational safety and health</a>
              in factories and docks in India. It deals with various problems
              concerning safety, health, efficiency and well-being of the
              persons at work places. It was replaced by the
              <a>Occupational Safety, Health and Working Conditions Code,2020.</a>
            </p>
            <br />
            <p>
              The Act is administered by the Ministry of Labour and Employment
              in India through its Directorate General Factory Advice Service &
              Labour Institutes (DGFASLI) and by the State Governments through
              their factory inspectorates. DGFASLI advises the Central and State
              Governments on administration of the Factories Act and
              coordinating the factory inspection services in the States.
            </p>
            <br />
            <p>
              The Act is applicable to any factory using power & employing 10 or
              more workers and if not using power, employing 20 or more workers
              on any day of the preceding twelve months, and in any part of
              which a manufacturing process is being carried on with the aid of
              power, or is ordinarily so carried on, or whereon twenty or more
              workers are working, or were working on any day of the preceding
              twelve months, and in any part of which a manufacturing process is
              being carried on without any power.
            </p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/about/FACTORY-ACT.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>