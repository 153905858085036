<div class="banner-header section-padding valign bg-img bg-fixed bg-position-bottom" data-overlay-dark="0"
  data-background="" loading="lazy" >
  <div class="container">
    <div class="row">
      <div class="col-md-12 caption text-center">
        <h4 style="color:black">Get in touch</h4>
        <h1 class="animate__animated animate__flipInX">Contact Us</h1>
      </div>
    </div>
  </div>
</div>

<section class="contact section-padding" data-scroll-index="1">
  <div class="container">
    <div class="row mb-30">
      <div class="col-md-3">
        <div class="sub-title border-bot-light">Location</div>
      </div>
      <div class="col-md-9">
        <div class="section-title">Contact Us</div>
        <h5>Book your appointment now</h5>
        <p class="mb-30">Fill in your contact details, let us know how we can assist and one of
          our team will be in touch as soon as possible.</p>
        <div class="row">
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-12">
                <div class="reservations mb-15">
                  <div class="icon">
                    <span><i class='bx bx-mobile-alt'></i></span>
                  </div>
                  <div class="text">
                    <p>Reservation</p>
                    <a target="_blank" href="tel:+91 7218024999" class="text-decoration-none">+91 7218024999</a>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="reservations mb-15">
                  <div class="icon">
                    <span><i class='bx bx-envelope'></i></span>
                  </div>
                  <div class="text">
                    <p>Email Info</p>
                    <a target="_blank" href="mailto:foodcognics@gmail.com" class="card-link text-decoration-none">foodcognics@gmail.com</a>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="reservations mb-15">
                  <div class="icon">
                    <span><i class='bx bx-location-plus'></i></span>
                  </div>
                  <div class="text">
                    <p>Address</p>
                    <p>Office No.208,<br /> Jaymala Business Court, <br />Nandini Takle Nagar, Manjri BK , <br /> Pune,
                      Maharashtra, 412307<br></p>
                  </div>
                </div>
              </div>
            </div>
          </div>          
          <!-- Form -->
          <div class="col-md-7 offset-md-1" style="background-color: #ffff;">
            <div class="row">
              <div class="col-md-12">
                <br><br>
                <h3 class="text-center">Get in touch</h3>
                <form name="editForm" role="form" novalidate (ngSubmit)="onSubmit()" [formGroup]="editForm">
                  <div class="col-md-12 form-group inputWithIcon">

                    <input type="text" name="name" class="form-control bi bi-person" id="name" formControlName="name"
                      placeholder="Your Name" />
                     <i class = "fa fa-user fa-lg fa-fw" aria-hidden = "true"> </i> 
                     <div *ngIf="
                        editForm.get('name')!.invalid &&
                        (editForm.get('name')!.dirty || editForm.get('name')!.touched)
                      ">
                      <small class="form-text text-danger" *ngIf="editForm.get('name')?.errors?.['required']">
                        This field is required.
                      </small>
                    </div>
                  </div>

                  <div class="col-md-12 form-group inputWithIcon">
                    <input type="text" name="email" class="form-control" id="email" formControlName="email"
                      placeholder="Your Email" />
                      <i class="fa fa-envelope fa-lg fa-fw" style="font-size: 13px"></i>
                      <div *ngIf="
                        editForm.get('contactNumber')!.invalid &&
                        (editForm.get('email')!.dirty || editForm.get('email')!.touched)
                      ">
                      <small class="form-text text-danger" *ngIf="editForm.get('email')?.errors?.['required']">Email is
                        required</small>
                      <small class="form-text text-danger" *ngIf="editForm.get('email')?.errors?.['email']">Email must
                        be a valid
                        email address.</small>
                    </div>
                  </div>

                  <div class="col-md-12 form-group inputWithIcon">
                    <input type="text" name="contactNumber" class="form-control" id="contactNumber"
                      formControlName="contactNumber" placeholder="Your Number" />
                      <i class="fa fa-phone fa-lg fa-fw" style="font-size: 17px"></i>
                    <div *ngIf="
                        editForm.get('contactNumber')!.invalid &&
                        (editForm.get('contactNumber')!.dirty ||
                          editForm.get('contactNumber')!.touched)
                      ">
                      <small class="form-text text-danger" *ngIf="editForm.get('contactNumber')?.errors?.['required']">
                        This field is required.
                      </small>
                      <small class="form-text text-danger"
                        *ngIf="editForm.get('contactNumber')?.errors?.['pattern']">Please enter
                        a 10-digit Mobile Number</small>
                    </div>
                  </div>

                  <div class="col-md-12 form-group inputWithIcon">
                    <textarea class="form-control" name="requirements" rows="2" placeholder="Requirements"
                      formControlName="requirements"></textarea>
                      <i class="fa fa-envelope fa-lg fa-fw" style="font-size: 13px; margin-top:2px"></i>
                    <div
                      *ngIf="editForm.get('requirements')!.invalid && (editForm.get('requirements')!.dirty || editForm.get('requirements')!.touched)">
                      <small class="form-text text-danger"
                        *ngIf="editForm.get('requirements')?.errors?.['required']">This field is required</small>
                    </div>
                  </div>

                  <div *ngIf="showThankYouMessage" class="text-center text-success">
                    Thank You for contacting us. Our team will get back to you shortly.
                  </div>

                  <div class="col-md-12 form-group">
                    <button class="button text-red hover:before:bg-redborder-red-500 relative h-[50px] w-28 ml-40 mt-3
                      overflow-visible border border-sky-600 bg-sky-600 px-6 text-white shadow-sm  transition-all 
                     before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-blue-400 
                     before:transition-all before:duration-400 hover:text-black hover:shadow-blue-400 hover:before:left-0 
                     hover:before:w-full"><span class="relative z-10">Book</span></button>

                  </div>
                </form> 
                <br>
                <div id="success-popup" class="popup">
                  <div class="popup-content">
                    <p>Form submitted successfully!</p>
                    <button id="close-popup">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>