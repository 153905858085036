<div class="icons-container">
  <a target="_blank" href="tel:+91 7218024999" class="call-button btn btn-primary"><i
      class="bi bi-telephone-fill"></i></a>
</div>
<div class="main">
  <header class="header" id="header">
    <div class="info-bar">
      <div class="container">
        <div class="header-content">
          <div class="location-div header-item">
            <div class="stm-iconbox2 d-flex align-items-center">
              <i class="bi bi-telephone-fill" style="margin-right: 8px; color: orange; font-size: larger"></i>
              <div class="stm-iconbox__info">
                <div class="stm-iconbox__text stm-iconbox__text_nomargin">
                  <a target="_blank" href="tel:+91%7218024999" style="color: white">+91 7218024999</a>
                </div>
              </div>
            </div>
          </div>
          <div class="right-content">
            <div class="socials">
              <ul class="d-flex align-items-center">
                <li>
                  <a target="_blank" href="https://www.facebook.com/foodcognics/" target="_blank"
                    class="social-facebook">
                    <i class="bi bi-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/foodcognics/" target="_blank" class="linkedin social-link">
                    <i class="bi bi-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/FoodCognics" target="_blank" class="social-twitter">
                    <i class="bi bi-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://youtube.com/@foodcognics9031" target="_blank" class="social-youtube">
                    <i class="bi bi-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="navigation" id="navigation">
    <div class="container d-flex align-items-center justify-content-lg-between">
      <a href="index.html" class="logo me-lg-0">
        <img src="assets/foodSlider/Foodcognics-Logo.png" alt="Logo" class="img-fluid" />
      </a>
      <nav id="navbar" class="navbar navbar-expand-lg order-last order-lg-0">
        <ul class="desktop-menu d-flex justify-content-center" *ngIf="!isMenuOpen" (click)="scrollToTop()">
          <li><a class="nav-link" (click)="scrollToTop()" routerLink="about">About</a></li>
          <div mdbDropdown class="dropdown" style="margin-top: -2px">
            <span class="service-container">
              <a href="#" class="service">Service</a>
              <i class="bi bi-caret-down-fill"></i>
            </span>
            <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="line-height: 16px">
              <li *ngFor="let service of turnkeyProjectServices">
                <div mdbDropdown class="dropdown" (click)="scrollToTop()">
                  <a class="dropdown-item" [routerLink]="service.link">{{ service.name }}<i
                      class="bi bi-caret-right-fill"></i></a>
                  <ul mdbDropdownMenu class="dropdown-menu custom-dropdown" aria-labelledby="nestedDropdownMenuLink"
                    style="margin-left: 13px; line-height: 8px">
                    <li *ngFor="let subService of service.subServices">
                      <a class="dropdown-item" [routerLink]="subService.link">{{ subService.name }}</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li><a class="dropdown-item" routerLink="services/newProductDevelopment">New Product Development</a></li>
              <li>
                <div mdbDropdown class="dropdown">
                  <a class="dropdown-item" routerLink=""><span>Licensing & Regulatory Services<i
                        class="bi bi-caret-right-fill"></i></span></a>
                  <ul mdbDropdownMenu class="dropdown-menu custom-dropdown" aria-labelledby="nestedDropdownMenuLink"
                    style="line-height: 8px; margin-left: 13px">
                    <li>
                      <div mdbDropdown class="dropdown" (click)="scrollToTop()">
                        <a class="dropdown-item"><span>Licensing (Mandatory)<i
                              class="bi bi-caret-right-fill"></i></span></a>
                        <ul mdbDropdownMenu class="dropdown-menu custom-dropdown"
                          aria-labelledby="nestedDropdownMenuLink" style="line-height: 10px; margin-left: 13px">
                          <li *ngFor="let mandatoryService of mandatoryServices">
                            <a class="dropdown-item" [routerLink]="mandatoryService.link">{{ mandatoryService.name
                              }}</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div mdbDropdown class="dropdown" (click)="scrollToTop()">
                        <a class="dropdown-item" routerLink=""><span>Certifications (Voluntary)<i
                              class="bi bi-caret-right-fill"></i></span></a>
                        <ul mdbDropdownMenu class="dropdown-menu custom-dropdown"
                          aria-labelledby="nestedDropdownMenuLink" style="line-height: 8px; margin-left: 13px">
                          <li *ngFor="let voluntaryService of voluntaryServices">
                            <a class="dropdown-item" [routerLink]="voluntaryService.link">{{ voluntaryService.name
                              }}</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <div mdbDropdown class="dropdown" (click)="scrollToTop()">
                  <a class="dropdown-item">Financial Services<span><i class="bi bi-caret-right-fill"></i></span></a>
                  <ul mdbDropdownMenu class="dropdown-menu custom-dropdown" aria-labelledby="nestedDropdownMenuLink"
                    style="line-height: 8px; margin-left: 13px">
                    <li *ngFor="let financialService of financialServices">
                      <a class="dropdown-item" [routerLink]="financialService.link">{{ financialService.name }}</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a class="dropdown-item" routerLink="services/technicalServices">IT Enabled Food Industry</a>
              </li>
              <li>
                <div mdbDropdown class="dropdown" (click)="scrollToTop()">
                  <a class="dropdown-item">Government schems & Subsidy<span><i
                        class="bi bi-caret-right-fill"></i></span></a>
                  <ul mdbDropdownMenu class="dropdown-menu custom-dropdown" aria-labelledby="nestedDropdownMenuLink"
                    style="line-height: 8px; margin-left: 13px">
                    <li *ngFor="let govtService of governmentServices">
                      <a class="dropdown-item" [routerLink]="govtService.link">{{ govtService.name }}</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <div mdbDropdown class="dropdown" (click)="scrollToTop()">
                  <a class="dropdown-item">Auditing & Training Services<span><i
                        class="bi bi-caret-right-fill"></i></span></a>
                  <ul mdbDropdownMenu class="dropdown-menu custom-dropdown" aria-labelledby="nestedDropdownMenuLink"
                    style="line-height: 8px; margin-left: 13px">
                    <li *ngFor="let audtService of auditingServices">
                      <a class="dropdown-item" [routerLink]="audtService.link">{{ audtService.name }}</a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <div mdbDropdown class="dropdown" (click)="scrollToTop()">
                  <a class="dropdown-item">Value Added Services<span><i class="bi bi-caret-right-fill"></i></span></a>
                  <ul mdbDropdownMenu class="dropdown-menu custom-dropdown" aria-labelledby="nestedDropdownMenuLink"
                    style="line-height: 8px; margin-left: 13px">
                    <li *ngFor="let valService of valueServices">
                      <a class="dropdown-item" [routerLink]="valService.link">{{ valService.name }}</a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <li><a class="nav-link scrollto" href="#portfolio"><span>Segments</span></a></li>
          <li><a class="nav-link" routerLink="Food-Startup-Program">Food Startup Program</a></li>
          <li><a class="nav-link scrollto" routerLink="career">Career</a></li>
          <li><a class="nav-link scrollto" routerLink="gallery">Gallery</a></li>
          <li><a class="nav-link scrollto" routerLink="contact">Contact Us</a></li>
          <li>
            <div mdbDropdown class="dropdown" style="margin-top: -2px">
              <span class="service-container">
                <a href="#" class="service">Blog</a>
                <i class="bi bi-caret-down-fill"></i>
              </span>
              <ul mdbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink"
                style="line-height: 16px;margin-left:-12px">
                <li *ngFor="let blogItem of blogItems">
                  <div mdbDropdown class="dropdown" (click)="scrollToTop()">
                    <a class="dropdown-item" target="_blank" [href]="blogItem.link">{{ blogItem.title }} <br />{{
                      blogItem.title1}} </a>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <nav class="navbar order-last order-lg-0 navbar-mobile" *ngIf="isMenuOpen">
          <ul>
            <li>
              <a class="nav-link scrollto" (click)="closeMenu()" routerLink="about">About</a>
            </li>
            <li>
              <a class="nav-link scrollto" (click)="closeMenu()" href="#portfolio">Segments</a>
            </li>
            <div class="dropdown">
              <a class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                Service
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (click)="scrollToTop()">
                <ng-container *ngFor="let service of services">
                  <a *ngIf="service.children; else noChildren" class="dropdown-item" (click)="scrollToTop()">
                    <span>{{ service.name }}<i class="bi bi-caret-right-fill"></i></span>
                    <div class="dropdown-menu dropdown1">
                      <ng-container *ngFor="let child of service.children">
                        <a class="dropdown-item" (click)="closeMenu()" [routerLink]="child.routerLink">{{ child.name
                          }}</a>
                      </ng-container>
                    </div>
                  </a>
                  <ng-template #noChildren>
                    <a class="dropdown-item" (click)="closeMenu()" [routerLink]="service.routerLink">{{ service.name
                      }}</a>
                  </ng-template>
                </ng-container>
              </div>
            </div>
            <li>
              <a class="nav-link scrollto" (click)="closeMenu()" routerLink="Food-Startup-Program">Food Startup
                Program</a>
            </li>
            <li>
              <a class="nav-link scrollto" (click)="closeMenu()" routerLink="career">Career</a>
            </li>
            <li>
              <a class="nav-link scrollto" (click)="closeMenu()" routerLink="gallery">Gallery</a>
            </li>
            <li>
              <a class="nav-link scrollto" (click)="closeMenu()" routerLink="contact">Contact Us</a>
            </li>
            <div class="dropdown">
              <a class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" (click)="closeMenu()" routerLink="#">
                Blog
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" (click)="scrollToTop()">
                <a class="dropdown-item" href="#" style="flex-direction: column;">
                  <li *ngFor="let blogItem of blogItems">
                    <div mdbDropdown class="dropdown" (click)="scrollToTop()">
                      <a class="dropdown-item -mt-3 drop" target="_blank" [href]="blogItem.link">{{ blogItem.title }}
                        <br />{{
                        blogItem.title1}}
                      </a>
                    </div>
                  </li>
                </a>
              </div>
            </div>
          </ul>
          <i class="bi mobile-nav-toggle" [ngClass]="{ 'bi-x': isMenuOpen, 'bi-list': !isMenuOpen }"
            (click)="toggleMenu()"></i>
        </nav>
        <i class="bi mobile-nav-toggle" [ngClass]="{ 'bi-list': !isMenuOpen }" (click)="toggleMenu()"></i>
      </nav>
      <a href="#" class="get-started-btn scrollto" data-bs-toggle="modal" data-bs-target="#quoteModal">Get A Quote</a>
    </div>
  </div>
</div>
<!-- Modal -->
<div class="modal fade" id="quoteModal" tabindex="-1" aria-labelledby="quoteModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content bg-dark">
      <div class="modal-header text-center" style="align-self: center; color: greenyellow">
        <h3 class="modal-title" id="quoteModalLabel">Get a Quote</h3>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          style="background-color: white; margin-left: 14px"></button>
      </div>
      <div class="modal-body">
        <form name="editForm" role="form" novalidate (ngSubmit)="save()" [formGroup]="editForm">
          <div class="row">
            <div class="col-md-6 form-group">
              <input type="text" name="name" class="form-control" id="name" formControlName="name"
                placeholder="Your Name" />
              <div
                *ngIf="editForm.get('name')!.invalid && (editForm.get('name')!.dirty || editForm.get('name')!.touched)">
                <small class="form-text text-danger" *ngIf="editForm.get('name')?.errors?.['required']"> This field is
                  required. </small>
              </div>
            </div>
            <div class="col-md-6 form-group mt-3 mt-md-0">
              <input type="text" class="form-control" name="Mobile No." id="Mobile No." placeholder="Mobile No."
                formControlName="contactNumber" />
              <div *ngIf="
                  editForm.get('contactNumber')!.invalid && (editForm.get('contactNumber')!.dirty || editForm.get('contactNumber')!.touched)
                ">
                <small class="form-text text-danger" *ngIf="editForm.get('contactNumber')?.errors?.['required']">
                  This field is required.
                </small>
                <small class="form-text text-danger" *ngIf="editForm.get('contactNumber')?.errors?.['pattern']">
                  Please, Enter 10 digit Mobile Number
                </small>
              </div>
            </div>
          </div>
          <div class="form-group mt-3">
            <input type="email" class="form-control" name="email" id="email" placeholder="Your Email"
              formControlName="email" />
            <div
              *ngIf="editForm.get('contactNumber')!.invalid && (editForm.get('email')!.dirty || editForm.get('email')!.touched)">
              <small class="form-text text-danger" *ngIf="editForm.get('email')?.errors?.['required']">Email is
                required</small>
              <small class="form-text text-danger" *ngIf="editForm.get('email')?.errors?.['email']">Email must be a
                valid email address.</small>
            </div>
          </div>
          <div class="form-group mt-3">
            <textarea class="form-control" name="requirement" rows="5" placeholder="requirements"
              formControlName="requirements">
            <div *ngIf="editForm.get('requirements')!.invalid && (editForm.get('requirements')!.dirty || editForm.get('requirements')!.touched)">
              <small class="form-text text-danger" *ngIf="editForm.get('requirements')?.errors?.['required']">This field is required</small>
            </div></textarea>
          </div>
          <div *ngIf="showThankYouMessage" class="text-center thank-you-message">
            Thank You for Contacting us. Our Executive will revert to you shortly.
          </div>
          <div class="text-center" style="margin-top: 5px">
            <button class="btn btn-success" [disabled]="editForm.invalid || isSaving" type="submit" value="send"
              style="margin-left: 0px">
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>