<div *ngIf="selectedItem === 'Food-Safety-Audits'">
<div class="row">
  <div class="col-sm-3">
    <div class="lftcolumn animate__animated animate__zoomInUp">
      <ul>
        <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
        <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
        <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory Services</a></li>
        <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
        <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
        <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
        <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training Services</a></li>
        <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
      </ul>
    </div>
  </div>
<div class="col-sm-9">
  <div class="container">
  <div>
    <div class="project-details">
      <h1 class="animate__animated animate__fadeInLeftBig">Food Safety Audits</h1>

      <div class="section animate__animated animate__fadeInUpBig">
      <i class='bx bxs-hand-right'></i>
        <p>Regulatory Compliance Audits</p>
      <i class='bx bxs-hand-right'></i>
        <p>HACCP (Hazard Analysis and Critical Control Points) Audits</p>
      <i class='bx bxs-hand-right'></i>
        <p>GMP (Good Manufacturing Practices) Audits</p>
      <i class='bx bxs-hand-right'></i>
        <p>Supplier Audits</p>
      <i class='bx bxs-hand-right'></i>
        <p>Documentation Review</p>
      <i class='bx bxs-hand-right'></i>
        <p>Training and Education Audits</p>
      <i class='bx bxs-hand-right'></i>
        <p>Food Defense Audits</p>
      <i class='bx bxs-hand-right'></i>
        <p>Continuous Improvement Recommendations</p>
      </div>
    </div>
  </div>
  <div class="content animate__animated animate__fadeInRightBig">
    <img src="../../../assets/GOVT-services/Food Safety Audit FoodCognics.jpg" alt="Project Image" />
  </div>
</div>
</div>
</div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Manufacturing-Audits'">
  <div class="row">
    <div class="col-sm-3">
      <div class="lftcolumn animate__animated animate__zoomIn">
        <ul>
          <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
          <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
          <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory Services</a></li>
          <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
          <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
          <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
          <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training Services</a></li>
          <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
        </ul>
      </div>
    </div>
  <div class="col-sm-9">
  <div class="container" data-aos="fade-right">
    <div>
      <div class="project-details">
        <h1 class="animate__animated animate__fadeInLeftBig">Manufacturing Audits</h1>
        <div class="section animate__animated animate__fadeInUpBig">
        <i class='bx bxs-hand-right'></i>
          <p>Quality Management System (QMS) Audits</p>
        <i class='bx bxs-hand-right'></i>
          <p>Process Audits</p>
        <i class='bx bxs-hand-right'></i>
          <p>Equipment and Machinery Audits</p>
        <i class='bx bxs-hand-right'></i>
          <p>Environmental Health and Safety (EHS) Audits</p>
        <i class='bx bxs-hand-right'></i>
          <p>Energy Efficiency Audits</p>
        <i class='bx bxs-hand-right'></i>
          <p>Inventory and Materials Management Audits</p>
        </div>
      </div>
    </div>
    <div class="content animate__animated animate__fadeInRightBig">
      <img src="../../../assets/GOVT-services/Manufacturing Audit FoodCognics.jpg" alt="Project Image" />
    </div>
  </div>
  </div>
  </div>
  </div>
  <!--  -->
  <div *ngIf="selectedItem === 'Vendor-Audit'">
    <div class="row">
      <div class="col-sm-3">
        <div class="lftcolumn animate__animated animate__zoomIn">
          <ul>
            <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
            <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
            <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory Services</a></li>
            <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
            <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
            <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
            <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training Services</a></li>
            <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
          </ul>
        </div>
      </div>
    <div class="col-sm-9">
    <div class="container" data-aos="fade-right">
      <div>
        <div class="project-details">
          <h1 class="animate__animated animate__fadeInLeftBig">Vendor Audit</h1>
          <div class="section animate__animated animate__fadeInUpBig">
          <i class='bx bxs-hand-right'></i>
            <p>Supplier Qualification Audits</p>
          <i class='bx bxs-hand-right'></i>
            <p>Supply Chain Security Audits</p>
          <i class='bx bxs-hand-right'></i>
            <p>Data Security Audits</p>
          <i class='bx bxs-hand-right'></i>
            <p>Continuous Improvement Recommendations</p>
          <i class='bx bxs-hand-right'></i>
            <p>Training and Competency Audits</p>
          </div>
        </div>
      </div>
      <div class="content animate__animated animate__fadeInRightBig">
        <img src="../../../assets/GOVT-services/Vendor Audit FoodCognics .jpg" alt="Project Image" />
      </div>
    </div>
    </div></div></div>
<!--  -->
  <div *ngIf="selectedItem === 'Organic-Certification-Training'">
    <div class="row">
      <div class="col-sm-3">
        <div class="lftcolumn animate__animated animate__zoomIn">
          <ul>
            <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
            <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
            <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory Services</a></li>
            <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
            <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
            <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
            <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training Services</a></li>
            <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
          </ul>
        </div>
      </div>
    <div class="col-sm-9">
    <div class="container" data-aos="fade-right">
      <div>
        <div class="project-details">
          <h1 class="animate__animated animate__fadeInLeftBig">Organic Certification Training</h1>
          <div class="section animate__animated animate__fadeInUpBig">
          <i class='bx bxs-hand-right'></i>
            <p>Customized Training Programs</p>
          <i class='bx bxs-hand-right'></i>
            <p>On-Site Workshops and Demonstrations</p>
          <i class='bx bxs-hand-right'></i>
            <p>Continuous Education and Updates</p>
          </div>
        </div>
      </div>
      <div class="content animate__animated animate__fadeInRightBig">
        <img src="../../../assets/GOVT-services/Organic Certification Training.jpg" alt="Project Image" />
      </div>
    </div>
    </div></div></div>
    <!--  -->
    <div *ngIf="selectedItem === 'auditingTrainingServices'">
      <div class="row">
        <div class="col-sm-3">
          <div class="lftcolumn animate__animated animate__zoomIn">
            <ul>
              <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
              <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
              <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory Services</a></li>
              <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
              <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
              <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
              <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training Services</a></li>
              <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
            </ul>
          </div>
        </div>
      <div class="col-sm-9">
      <div class="TS-hero">
        <img
          class="about_web"
          src="../../../../assets/sector/DPR.jpg"
          alt="Web Background"
        />
        <div class="overlay-container">
        </div>
      </div>
      <div class="container">
        <div class="inner-block">
          <div class="row align-items-center">
            <div class="col-md-8">
              <div class="TS-title">
                <div data-aos="flip-left" class="aos-init aos-animate">
                  <h1 class=" TS-title animate__animated animate__fadeInLeftBig">
                    <strong>Techno Economic Viability Study Report</strong>
                  </h1>
                </div>
              </div>
              <div data-aos="fade-right" class="aos-init aos-animate">
              <i class='bx bxs-hand-right'></i>
                <p>Identification of the risk associated with product.</p>
              <i class='bx bxs-hand-right'></i>
                <p>Forming risk mitigation strategies.</p>
              <i class='bx bxs-hand-right'></i>
                <p>Evaluating the financial risks.</p>
              </div>
            </div>
            <div class="col-md-4">
              <img src="../../../../assets/sector/DPR.jpg">
            </div>
          </div>
        </div>
      </div>
    </div></div></div>
    <!--  -->
    <div *ngIf="selectedItem === 'Energy-Audit'">
      <div class="row">
        <div class="col-sm-3">
          <div class="lftcolumn animate__animated animate__zoomIn">
            <ul>
              <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
              <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
              <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory Services</a></li>
              <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
              <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
              <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
              <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training Services</a></li>
              <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
            </ul>
          </div>
        </div>
      <div class="col-sm-9">
    <div class="container">
      <div>
        <div class="project-details">
          <h1 class="animate__animated animate__fadeInLeftBig">Energy Audit</h1>
    
          <div class="section animate__animated animate__fadeInUpBig">
          <i class='bx bxs-hand-right'></i>
            <p>Assistance in Transfering ownership</p>
          <i class='bx bxs-hand-right'></i>
            <p>Contract</p>
          </div>
        </div>
      </div>
      <div class="content animate__animated animate__fadeInRightBig">
        <img src="../../../assets/GOVT-services/Energy Auditing FoodCognics.jpg"  alt="Project Image" />
      </div>
    </div>
    </div></div></div>