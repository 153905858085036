import { Component } from '@angular/core';

@Component({
  selector: 'app-food-startup',
  templateUrl: './food-startup.component.html',
  styleUrls: ['./food-startup.component.scss']
})
export class FoodStartupComponent {

}
