import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AboutKnowmoreComponent } from './about-knowmore.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TestimonialsComponent } from '../testimonials/testimonials.component';

@NgModule({
  declarations: [
    AboutKnowmoreComponent,TestimonialsComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    RouterModule.forChild([
      {
        path: 'about',
        component: AboutKnowmoreComponent,
      },
    ]),
  ],
})
export class AboutusModule {}

