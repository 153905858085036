<div *ngIf="selectedItem === 'Monthly-Advisory-Services'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/GOVT-services/Value Added Services FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container">
    </div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Monthly Advisory Services</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <i class='bx bxs-hand-right'></i>
            <p>Scale up Assistance</p>
            <br />
            <p>
              Implementing strategies and measures to help businesses in the
              sector expand their operations with our team of food technologist as
              well as industry allied associates. Scale up assistance includes<br />
              a. Operational Efficiency<br />
              b. Market Research & Analysis<br />
              c. Improving and managing supply chain<br />
              d. Technology Integration<br />
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Assistance in selection of products</p>
            <br />
            <p>
              We assists clients initially for tap or targeting the potential
              products by analyzing the market size, market strengths, TAM (total
              addressable market).While suggesting the product to client we
              consider following points:-<br />
              a. Budget<br />
              b. Capacity<br />
              c. Location<br />
              d. Market Scope<br />
              e. Technology Used<br />
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Shelf Life Analysis</p>
            <br />
            <p>
              Our team of food technologist with their huge experienced are
              working on extension of shelf life of existing or new products.
              Tackling challenges arise in process of production , identification
              of pain point, suggesting the new innovative solutions to expand
              shelf life of product
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Vendor Selection and Management</p>
            <br />
            <p>
              According to the budget and capacity finalized, we help clients to
              select right vendors for project. Vendor management strategy
              creation, selection of vendors by studying their portfolio, existing
              clients, presence in market according to that we help clients to
              identify the potential vendor.
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Financial Management</p>
            <br />
            <p>
              Accounting is the language of business. Based on this theory we
              assist clients to take financial call , expansion of business ,
              product line.
            </p>
            <br />
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/Value Added Services/Monthly Advisory Service.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Contract-Manufacturing'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/GOVT-services/Value Added Services FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container">
    </div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Contract Manufacturing</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              FoodCognics services play a pivotal role in assisting companies in
              the food industry in various aspects of their business. Here is how
              the mentioned points apply to food consultancy services:
            </p>
            <i class='bx bxs-hand-right'></i>
            <p>Outsourcing Expertise</p>
            <br />
            <p>
              Our firms provide specialized expertise in areas such as product
              development, market trends, and regulatory compliance. Companies
              seeking to enhance their capabilities can leverage the knowledge and
              insights of food consultants.
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Cost Efficiency</p>
            <br />
            <p>
              By engaging food consultancy services, companies can access expert
              advice without the need for extensive in-house resources. This
              cost-effective approach allows businesses to benefit from industry
              knowledge without investing heavily in hiring specialized staff.<br />
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Expertise and Quality Control</p>
            <br />
            <p>
              Our consultants bring a wealth of knowledge in food science,
              technology, and quality control. They assist companies in
              maintaining high-quality standards, adhering to industry
              regulations, and ensuring product excellence.
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Scalability</p>
            <br />
            <p>
              FoodCognics services offer scalability by providing on-demand
              expertise. Whether a company is launching a new product or facing a
              specific challenge, consultants can be engaged as needed, allowing
              for flexibility in addressing different business requirements.
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Regulatory Compliance</p>
            <br />
            <p>
              Food consultancy services keep abreast of evolving food safety
              regulations. Companies can rely on consultants to navigate complex
              regulatory landscapes, ensuring that their products meet all legal
              requirements and comply with industry standards.
            </p>
            <br />
            <p>
              FoodCognics serve as strategic partners for companies in the food
              industry, offering specialized knowledge, cost-effective solutions,
              and flexibility. Whether focusing on product development, regulatory
              compliance, or other aspects, these services contribute to the
              overall success and competitiveness of food businesses.
            </p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/Value Added Services/Contract Manufacturing.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Production-Assistance-Service'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/GOVT-services/Value Added Services FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container">
    </div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Production Assistance Service</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              Production assistance services provided by Us in the food industry
              encompass a range of support to help companies optimize their
              manufacturing processes, ensure product quality, and navigate
              regulatory requirements. Here's an overview of the key aspects of
              production assistance offered by food consultancy services:
            </p>

            <i class='bx bxs-hand-right'></i>
            <p>Process Optimization</p>
            <br />
            <p>
              Food consultants assess and optimize the production processes to
              enhance efficiency and reduce costs. They may recommend improvements
              in manufacturing workflows, equipment utilization, and resource
              management.<br />
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Quality Control and Assurance</p>
            <br />
            <p>
              Food consultancy services play a crucial role in maintaining and
              improving product quality. Consultants help establish and implement
              quality control measures, ensuring that products meet industry
              standards and comply with regulatory requirements.
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Product Development</p>
            <br />
            <p>
              Food consultants contribute to the creation of new food products or
              the improvement of existing ones. They may provide insights into
              market trends, consumer preferences, and innovative ingredients or
              processes.
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>HACCP Implementation</p>
            <br />
            <p>
              Consultants guide companies in implementing Hazard Analysis and
              Critical Control Points (HACCP) systems. This systematic approach to
              food safety helps identify, evaluate, and control hazards throughout
              the production process.
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Supplier and Ingredient Management</p>
            <br />
            <p>
              Consultants assist in selecting reliable suppliers and managing
              ingredient sourcing. This involves evaluating supplier performance,
              ensuring ingredient traceability, and maintaining quality assurance
              throughout the supply chain.
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Cost Analysis and Budgeting</p>
            <br />
            <p>
              Consultants help companies analyze production costs and develop
              budgeting strategies. This includes evaluating the
              cost-effectiveness of different production methods, identifying
              areas for cost reduction, and optimizing resource allocation
            </p>
            <br />
            <p>
              In production assistance services provided bFoodCognics are
              comprehensive, covering aspects from process optimization to
              regulatory compliance and sustainability. These services aim to help
              food companies enhance their production capabilities, produce
              high-quality products, and stay competitive in the dynamic food
              industry.
            </p>
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/Value Added Services/Production Assistance Service Upper or down.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'Merger-&-Acquisition'">
  <div class="TS-hero">
    <img class="about_web animate__animated animate__backInDown"
      src="../../../assets/GOVT-services/Value Added Services FoodCognics.jpg" alt="Web Background" />
    <div class="overlay-container">
    </div>
  </div>
  <div class="container">
    <div class="inner-block">
      <div class="row align-items-center">
        <div class="col-md-8">
          <h1 class="TS-title animate__animated animate__fadeInLeft">
            <strong>Merger & Acquisition</strong>
          </h1>
          <div class="animate__animated animate__backInRight">
            <p>
              In the context of food consultancy services, businesses specializing
              in M&A advisory within the food industry play a crucial role in
              facilitating strategic transactions. Here's how the mentioned points
              apply to food consultancy services:
            </p>

            <i class='bx bxs-hand-right'></i>
            <p>Market Consolidation</p>
            <br />
            <p>
              We assist clients in navigating the landscape of market
              consolidation. They provide expertise in identifying potential
              acquisition targets or suitable merger partners to help clients
              strengthen their market position.
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Efficiency and Cost Savings</p>
            <br />
            <p>
              Consultants focus on operational synergies and cost-saving
              opportunities. They help clients assess the feasibility of
              consolidating production facilities, optimizing distribution
              networks, and streamlining administrative functions to achieve
              operational efficiencies.
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Diversification</p>
            <br />
            <p>
              FoodsCognics advise clients on diversifying their product
              portfolios. They analyze market trends, consumer preferences, and
              business strategies to recommend acquisitions or mergers that align
              with the client's goals for diversification.
            </p>
            <br />
            <i class='bx bxs-hand-right'></i>
            <p>Access to New Markets</p>
            <br />
            <p>
              Our services assist companies in expanding into new markets. This
              involves strategic analysis of potential mergers or acquisitions
              that provide access to different geographic regions and consumer
              demographics.
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Brand Strengthening</p>
            <br />
            <p>
              Our Experts contribute to brand strengthening by advising on the
              acquisition of well-established or niche brands. They assess the
              market value, consumer perception, and strategic fit of target
              brands to enhance the overall brand strength of the client.
            </p>
            <br />

            <i class='bx bxs-hand-right'></i>
            <p>Innovation and R&D</p>
            <br />
            <p>
              Our expert consultants with a focus on M&A provide insights into
              innovation and research and development capabilities. They help
              clients identify companies with innovative technologies or unique
              R&D initiatives, supporting accelerated product development
            </p>
            <br />
          </div>
        </div>
        <div class="col-md-4 animate__animated animate__zoomInUp">
          <img src="../../../assets/Value Added Services/Merger And Aquisition.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->