<!--  -->
<div *ngIf="selectedItem === 'MoFPI'">
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-3">
        <div class="lftcolumn animate__animated animate__zoomInUp">
          <ul>
            <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
            <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
            <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory
                Services</a></li>
            <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
            <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
            <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
            <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training
                Services</a></li>
            <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
          </ul>
        </div>
      </div>

      <div class="col-sm-9 title">
        <h1 class="animate__animated animate__fadeInLeft">MOFPI</h1>

        <div class="row innercontent">
          <div class="col-sm-12 animate__animated animate__fadeInRightBig">
            <p class="text-justify">
              <img src="../../../assets/GOVT-services/MoFPI Schemes SIDE.jpg"
                class="pull-right img-responsive img-thumbnail marginlft20" alt="" />
              The Ministry of Food Processing Industries is a government
              department in India that is responsible for formulating and
              implementing policies and programs for the development and
              promotion of the food processing industry. Its main goal is to
              enhance the value of agricultural produce, minimize wastage, and
              create employment opportunities in the food processing sector.<br />
              <i class='bx bxs-hand-right' aria-hidden="true"></i> <strong>MoFPI-PMKSY Schemes:</strong><br />
              The Pradhan Mantri Kisan Sampada Yojana (PM-Kisan SAMPADA Yojana), which translates to Prime Minister
              Farmer Wealth Creation Scheme, is a program launched by the Government of India. It aims to create a
              robust and efficient post-harvest infrastructure for the agricultural sector.
              Objectives: The primary objectives of the scheme include modernizing food processing infrastructure,
              reducing wastage at various stages of the food supply chain, increasing the processing levels, enhancing
              shelf life of perishable produce, and promoting value addition.<br />
              <i class='bx bxs-hand-right' aria-hidden="true"></i><strong>Schemes:</strong><br />
              1. Integrated Cold Chain and Value Addition Infrastructure<br />
              2. Creation/ Expansion of Food Processing and Preservation
              Capacities (Unit Scheme)<br />
              3. Infrastructure for Agro-processing Clusters<br />
              4. Food Safety and Quality Assurance Infrastructure<br />
              5. Human Resources and Institutions –Research & Development<br />
              6. Mega food parks<br />
              <b>Financial Assistance:</b> Financial assistance is provided in the form of grants-in-aid ranging from
              35% to
              75% of the eligible project cost, depending on the category of the project and the location.<br />

              <i class='bx bxs-hand-right' aria-hidden="true"></i> <strong>MoFPI-PMFME Scheme:</strong><br />
              The PMFME scheme stands for Pradhan Mantri Formalisation of Micro food processing Enterprises. It's an
              initiative launched by the Government of India's Ministry of Food Processing Industries (MoFPI) under the
              Atmanirbhar Bharat Abhiyan (Self-reliant India Mission).<br />


              <b>● Formalize Micro Food Processing Units: </b>The scheme aims to bring existing small food processing
              businesses in the unorganized sector into the formal fold. This helps them access credit, government
              benefits, and operate under regulations.<br />
              <b>● Support Farmer Producer Organizations (FPOs), Cooperatives &
                Self-Help Groups (SHGs):</b> The PMFME scheme offers specific support to these groups engaged in
              agri-food
              processing. <br />
              <b>● Increase Micro-enterprises:</b>By providing financial and technical assistance, the scheme aims
              to encourage the growth of micro-enterprises in the food processing sector.<br />

              <i class='bx bxs-hand-right' aria-hidden="true"></i> <strong>Schemes:</strong><br />
              <b>Credit Linked Subsidy </b><br />
              Individuals / Proprietorship / Partnership/ FPOs/ NGOs/ Cooperatives/ SHGs/ Pvt. Ltd. Co. would be
              provided credit-linked capital subsidy @35% of the eligible project cost - maximum of Rs. 10.0 lakhs per
              unit for upgradation or setting up of new units.<br />

              <b>Seed Capital for SHGs</b><br />
              Seed capital @ Rs. 40,000/- per member of SHG for working capital and purchase of small tools maximum upto
              4 Lakhs per SHG. Seed Capital given as grant to the SHG Federation by SRLM/SULM through SNA for loan to
              the members of SHGs.<br />

              <b>Common Infrastructure</b><br />
              The Groups - FPOs/ FPCs/ Cooperatives/ SHG and its federation/ Govt. Agencies - established or propose to
              establish food processing line along with common infrastructure/ Value chain/ incubation centers would be
              provided credit linked capital subsidy @35% of the eligible project cost (Rs. 10 Cr) with a maximum of Rs.
              3.00 Crore.<br />

              <b>Marketing & Branding</b><br />
              The scheme provides 50% financial grant for Branding and Marketing Support to groups of FPOs/ SHGs/
              Cooperatives or a SPV of micro food processing enterprises to promote their existing or proposed brands to
              market their processed food products under the scheme<br />

              <b>Capacity Building The</b><br />
              The Capacity Building component under PMFME Scheme envisages to provide training on Food Processing
              Entrepreneurship Development Programme to the PMFME beneficiaries.<br />

              <b>ODOP</b><br />
              One District One Product (ODOP) approach to reap the benefit of scale in terms of procurement of inputs,
              availing common services and marketing of products. It aims to provide the framework for value chain
              development and alignment of support infrastructure. ODOP approved for 713 districts in 35 States/UTs with
              137 unique products. NHB-(National Horticultural Board) <br/>

              <i class='bx bxs-hand-right' aria-hidden="true"></i> <strong>Fees:</strong>
              No need to pay fee because it is centrally sponsored scheme<br />
              <i class='bx bxs-hand-right' aria-hidden="true"></i> <strong>Age limit:</strong>
              Above 18 years<br />
              <i class='bx bxs-hand-right' aria-hidden="true"></i> <strong>Educational qualification:</strong>
              At least 8th standard pass
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'MSME'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-3">
        <div class="lftcolumn animate__animated animate__zoomInUp">
          <ul>
            <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
            <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
            <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory
                Services</a></li>
            <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
            <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
            <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
            <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training
                Services</a></li>
            <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
          </ul>
        </div>
      </div>

      <div class="col-sm-9 title">
        <h1 class="animate__animated animate__fadeInLeft">MSME</h1>

        <div class="row innercontent">
          <div class="col-sm-12 animate__animated animate__fadeInRightBig">
            <p class="text-justify">
              <img src="../../../assets/GOVT-services/MSME Side Image.jpg"
                class="pull-right img-responsive img-thumbnail marginlft20" alt="" />
              MSMEs make up over 40% of India's exports, 45% of the industrial
              output, and close to 8% of the GDP of the nation. They can be
              appropriately referred to as the "Backbone of the country.<br />
              In compliance with the Micro, Small and Medium Enterprises
              Development (MSMED) Act of 2006, the Indian government has
              implemented MSME, or Micro, Small, and Medium Enterprises. These
              businesses produced, manufactured, processed, or preserved items
              and commodities as their primary business.<br />
              MSMEs play a significant role in the Indian economy and have made
              significant contributions to the socioeconomic advancement of the
              nation. It contributes to the development of the nation's rural
              and underdeveloped areas in addition to creating job
              opportunities. As to the Government's annual report for 2018–19,
              there.<br />
              <i class='bx bxs-hand-right' aria-hidden="true"></i> <strong>highlighting features of new
                MSMEs:</strong><br />
              1. A feature offering MSMEs Collateral-Free Loans<br />
              2. An agreement to provide Rs. 3 lac crores in loans to MSMEs<br />
              3. A 12-month moratorium period is being offered to MSMEs.<br />
              4. MSMEs in the manufacturing and service sectors are treated as
              one entity and are given a 48-month payback tenure.<br />
              5. MSMEs are guaranteed a credit score of 100%.<br />
              6. About 45 Lac units will gain from the reclassification of
              MSMEs.<br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'APEDA'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-3">
        <div class="lftcolumn animate__animated animate__zoomInUp">
          <ul>
            <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
            <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
            <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory
                Services</a></li>
            <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
            <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
            <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
            <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training
                Services</a></li>
            <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
          </ul>
        </div>
      </div>

      <div class="col-sm-9 title">
        <h1 class="animate__animated animate__fadeInLeft">APEDA</h1>

        <div class="row innercontent">
          <div class="col-sm-12 animate__animated animate__fadeInRightBig">
            <p class="text-justify">
              <img src="../../../assets/GOVT-services/APEDA side.jpg"
                class="pull-right img-responsive img-thumbnail marginlft20" alt="" />
              SCHEME FOR EXTENDING FINANCIAL ASSISTANCE TO SUGAR MILLS FOR
              ENHANCEMENT AND AUGMENTATION OF ETHANOL PRODUCTION CAPACITY.<br />
              LOANS FOR FOOD PARKS AND FOOD PROCESSING UNITS IN DESIGNATED FOOD
              PARKS.<br />
              <i class='bx bxs-hand-right'></i> <strong>highlighting features of new
                MSMEs:</strong><br />
              1. A feature offering MSMEs Collateral-Free Loans<br />
              2. An agreement to provide Rs. 3 lac crores in loans to MSMEs<br />
              3. A 12-month moratorium period is being offered to MSMEs.<br />
              4. MSMEs in the manufacturing and service sectors are treated as
              one entity and are given a 48-month payback tenure.<br />
              5. MSMEs are guaranteed a credit score of 100%.<br />
              6. About 45 Lac units will gain from the reclassification of
              MSMEs.<br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'NHB'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-3">
        <div class="lftcolumn animate__animated animate__zoomInUp">
          <ul>
            <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
            <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
            <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory
                Services</a></li>
            <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
            <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
            <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
            <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training
                Services</a></li>
            <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
          </ul>
        </div>
      </div>

      <div class="col-sm-9 title">
        <h1 class="animate__animated animate__fadeInLeft">NHB</h1>

        <div class="row innercontent">
          <div class="col-sm-12 animate__animated animate__fadeInRightBig">
            <p class="text-justify">
              <img src="../../../assets/GOVT-services/APEDA side.jpg"
                class="pull-right img-responsive img-thumbnail marginlft20" alt="" />
              The National Horticulture Board (NHB) was set up by the Government
              of India in 1984 as an Autonomous organization under the
              administrative control of Ministry of Agriculture and Farmers
              Welfare and registered as a society under Societies Registration
              Act with its headquarters at Gurugram. Presently, NHB has 29 field
              offices located all over the country. The broad aims and
              objectives of the Board are to develop production clusters/hubs
              for integrated Hi-tech commercial horticulture, development of
              Post-harvest and cold chain infrastructure, ensuring availability
              of quality planting material and to promote adoption of new
              technologies/tools/ techniques for Hi-tech commercial horticulture
              etc.<br />
              <i class='bx bxs-hand-right' aria-hidden="true"></i> <strong>Aims & Objectives of NHB
                Schemes:</strong><br />
              The main objectives of the NHB are to improve integrated
              development of Horticulture industry and to help in coordinating,
              sustaining the production and processing of fruits and vegetables.
              Detailed objectives of the Board are as under:-<br />
              1. Development of hi-tech commercial horticulture in identified
              belts and make such areas vibrant with horticultural activity,
              which in turn will act as hubs for development of horticulture.<br />
              2. Development of modern post-harvest management infrastructure as
              an integral part of area expansion projects or as common facility
              for cluster of projects.<br />
              3. Development of integrated, energy efficient cold chain
              infrastructure for fresh horticulture produce.<br />
              4. Popularization of identified new technologies / tools /
              techniques for commercialization / adoption, after carrying out
              technology and need assessment.<br />
              5. Assistance in securing availability of quality planting
              material by promoting setting up of scion and root stock banks /
              mother plant nurseries and carrying out accreditation / rating of
              horticulture nurseries and need based imports of planting
              material.<br />
              6. Promotion and market development of fresh horticulture
              produce.<br />
              7. Promotion of field trials of newly developed/imported planting
              materials and other farm inputs; production technology; PHM
              protocols, INM and IPM protocols and promotion of applied R&D
              programmes for commercialization of proven technology.<br />
              8. Promotion of Farm Mechanization in Horticulture through
              demonstration and its uses at farmers field level to reduce labour
              cost and increase the productivity of Horticulture crops.<br />
              9. Promotion of applied R & D for standardizing PHM protocols,
              prescribing critical storage conditions for fresh horticulture
              produce, bench marking of technical standards for cold chain
              infrastructure etc.<br />
              10. Transfer of technology to producers/farmers and service
              providers such as gardeners, nurserymen, farm level skilled
              workers, operators in cold storages, work force carrying out post
              harvest management including processing of fresh horticulture
              produce and to the master trainers.<br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div *ngIf="selectedItem === 'DAHD'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-3">
        <div class="lftcolumn animate__animated animate__zoomInUp">
          <ul>
            <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
            <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
            <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory
                Services</a></li>
            <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
            <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
            <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
            <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training
                Services</a></li>
            <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
          </ul>
        </div>
      </div>
      <div class="col-sm-9 title">
        <h1 class="animate__animated animate__fadeInLeft">DAHD</h1>
        <div class="row innercontent">
          <div class="col-sm-12 animate__animated animate__fadeInRightBig">
            <p class="text-justify">
              <img src="../../../assets/GOVT-services/DAHD SIDE.jpg"
                class="pull-right img-responsive img-thumbnail marginlft20" alt="" />
              The Department of Animal Husbandry and Dairying (AH&D) is one of
              the Departments of the newly created Ministry of Fisheries, Animal
              Husbandry & Dairying vide Cabinet Secretariat's Notification
              No.1/21/7/2019-Cab dated 17.06.2019 published in eGazette
              S.O.No.1972(E). The Department of Animal Husbandry and Dairying
              (AH&D) renamed as Department of Animal Husbandry, Dairying &
              Fisheries (DADF) was one of the Departments in the Ministry of
              Agriculture and came into existence w.e.f. 1st February 1991, by
              converting two divisions of the Department of Agriculture and
              Cooperation namely Animal Husbandry and Dairy Development into a
              separate Department. The Fisheries Division of the Department of
              Agriculture and Cooperation and a part of the Ministry of Food
              Processing Industries were later transferred to this Department
              w.e.f. 10th October 1997. The Department is located at Krishi
              Bhawan, New Delhi and parts of some Divisions of the Department
              are functioning from Chanderlok Building, Janpath, New Delhi.<br />
              The Department is responsible for matters relating to livestock
              production, preservation, protection from diseases and improvement
              of stocks and dairy development, and also for matters relating to
              Delhi Milk Scheme (DMS) and National Dairy Development Board
              (NDDB).<br />
              <i class='bx bxs-hand-right' aria-hidden="true"></i> <strong>The Department advises State
                Governments/Union Territories in formulation of policies and programme in
                the field of Animal Husbandry and Dairy Development. The main thrust areas are:</strong><br />
              1. Development of requisite infrastructure in States/ UTs for improving animal productivity.<br />
              2. Preservation and protection of livestock through provision of health care.<br />
              3. Strengthening of central livestock farms (Cattle, Sheep and Poultry) for development of superior
              germplasm for distribution to states.<br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->