<footer id="footer">
  <div class="footer-top">
    <div class="container" data-aos="fade-right">
      <div class="row">

        <div class="col-lg-2 col-md-3">
          <div class="footer-info">
            <img src="../../assets/foodSlider/Foodcognics-Logo.png">
            <p>
              Office No.208,<br/> Jaymala Business Court, <br/>Nandini Takle Nagar, Manjri BK , <br/> Pune, Maharashtra, 412307
            </p>
            <div class="social-links mt-3">
              <a target="_blank" href="https://twitter.com/FoodCognics" class="twitter"><i class="bx bxl-twitter"></i></a>
              <a target="_blank" href="https://www.facebook.com/foodcognics/" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a target="_blank" href="https://www.instagram.com/foodcognics_foodconsultant/?hl=en" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a target="_blank" href="https://youtube.com/@foodcognics9031" class="youtube"><i class="bx bxl-youtube"></i></a>
              <a target="_blank" href="https://www.linkedin.com/company/foodcognics/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 info" (click)="scrollToTop()" data-aos="fade-up" data-aos-duration="1000">
          <h4>Services</h4>
          <a routerLink="services/turnkeyProjectSetup/turnkeyProjectSetup"><span>Turnkey Project Setup</span></a><br/>
          <a routerLink="services/newProductDevelopment"><span>New Product Development</span></a><br/>
          <a routerLink="services/licensingRegulatoryServices/mandatory/FSSAI"><span>Licensing & Regulatory Services</span></a><br/>
          <a routerLink="services/financialServices/DPR"><span>Financial Services</span></a><br/>
          <a routerLink="services/technicalServices"><span>IT Enabled Food Industry</span></a><br/>
          <a routerLink="services/governmentSchemas/MoFPI"><span>Government Schemes & Subsidy</span></a><br/>
          <a routerLink="services/auditingTrainingServices/Food-Safety-Audits"><span>Auditing & Training Services</span></a><br/>
          <a routerLink="services/valueAddedService/Monthly-Advisory-Services"><span>Value Added Services</span></a><br/>
        </div>

        <div class="col-lg-2 col-md-3 info" (click)="scrollToTop()">
          <h4>Key Focus</h4>
         <a routerLink="food-categories/Beverages-&-Packaged-drinking-water"><span>Food & Beverage consultant</span></a><br/>
         <a routerLink="food-categories/Milk-&-Dairy-Products"> <span>Dairy consultant</span></a><br/>
         <a routerLink="food-categories/Frozen-Products"><span>Frozen Food Consultant</span></a> <br/>
         <a routerLink="food-categories/Spices-&-Condiments"><span>Nuts & Spices Consultant</span></a> <br/>
         <a routerLink="food-categories/Bakery-products"><span>Bakery Consultant</span></a>  <br/>
         <a routerLink="food-categories/Instant-Premixes"><span>RTE, RTC, RTS Food Consultant</span></a> <br/>
         <a routerLink="food-categories/Cereal-Products"><span>Cereals & Pulses Food Consultant</span></a><br/>
         <a routerLink="services/licensingRegulatoryServices/mandatory/FSSAI"><span>Regulatory Consultant</span></a><br/>
         <a routerLink="food-categories/Fruits-&-Vegetable"><span>Fruits & Vegetable processing Consultant</span></a><br/>
         <a routerLink="food-categories/Snacks-products"><span>Extruded Snacks Products manufacturing Consultant</span></a><br/>
         <a routerLink="services/newProductDevelopment"><span>New Food Product Development Consultant</span></a><br/>
         <a routerLink="services/turnkeyProjectSetup/Machinery-Selection"><span>Cold Storage Consultant</span></a><br/>
         <a routerLink="services/governmentSchemas/MoFPI"><span>MoFPI subsidy Consultants</span></a> <br/>
        </div>

        <div class="col-lg-2 col-md-3 info">
          <h4>Contact Us</h4>
          <div class="contact"> 
          <a target="_blank" href="tel:+91 7218024999"><span> <i class='bx bxs-phone-call' style="margin-right: 8px; color: orange;"></i><strong style="font-size: 18px;">+91 7218024999</strong></span></a> <br/>
          <a target="_blank" href="mailto:foodcognics@gmail.com"><span><i class='bx bx-envelope' style="color: orange;"></i>foodcognics@gmail.com</span></a><br/>
         <span><i class='bx bxs-calendar' style="color: orange;"></i>Monday - Saturday</span><br/>
         <span><i class='bx bxs-time' style="color: orange;"></i>10:00 AM. – 06:00 PM.</span><br/>
        </div>
        </div>
      </div>
    </div>
  </div>
  <a target="_blank" class="back-to-top d-flex align-items-center justify-content-center active cursor-pointer" (click)="scrollToTop()">
    <i class="bi bi-arrow-up-short"></i></a>
</footer>
<div class="footer_bottom">
  <div class="first_half">
    Copyright © 2023 <a target="_blank" href="https://foodcognics.com/">foodcognics.com</a> - All rights reserved.
  </div>
  <div class="second_half">
    <span>Terms and Conditions</span> |
    <span>Privacy Policy</span> |
  </div>
</div>
