<!-- hero section -->
<section id="hero" class="hero section" (click)="scrollToTop()">
  <div class="info d-flex align-items-center">
    <div class="container">
      <div class="row justify-content-center" data-aos="fade-up" data-aos-delay="100">
        <div class="col-lg-12 text-center">
          <h2>{{ carouselText }}</h2>
        </div>
      </div>
    </div>
  </div>  
  
  <div id="hero-carousel" class="carousel slide" data-bs-ride="carousel" data-bs-interval="5000" (slid.bs.carousel)="onSlideChange($event)">
      <div class="carousel-item" *ngFor="let image of heroImages; let i = index" [class.active]="i === 0">
        <img [src]="image" [alt]="'Slide ' + (i + 1)">
      </div>
    <a class="carousel-control-prev text-decoration-none" href="#hero-carousel" role="button" data-bs-slide="prev">
      <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
    </a>
    <a class="carousel-control-next text-decoration-none" href="#hero-carousel" role="button" data-bs-slide="next">
      <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
    </a>
  </div>

</section>

<!-- End Home Section  -->
<section id="about" class="about">
  <div class="container" data-aos="fade-up" data-aos-delay="200">
    <div class="row">
      <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="200">
        <img src="assets/about/About Us img1.jpg" class="img-fluid" alt="" />
      </div>
      <div class="para col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="200">
        <div class="sub-title border-bot-light w-36">About Us</div>
        <div data-aos="fade-right" data-aos-delay="120">
          <ul>
            Experienced and innovative Food Packaging Expert with a proven track record of over 10 years in the
            industry. Adept at
            developing and implementing cutting-edge packaging solutions that enhance product quality, shelf life, and
            sustainability.
            Specialized in optimizing packaging processes to meet regulatory requirements and improve overall
            efficiency. Our experts
            committed to driving innovation in the food packaging sector through a combination of technical expertise
            and a passion for
            sustainable practices..
            <br />
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End About Section -->
<section id="services" class="ml-px">
  <div class="container">
    <div class="row mb-30 p-4 md:flex md:flex-row">
      <div class="col-md-3">
        <div class="sub-title border-bot-light w-36">Services</div>
      </div>
      <div class="col-md-9">
        <div class="section-title" data-aos="fade-up">Our Services</div>
        <p>
          Explore vast opportunities in the food industry with comprehensive support, from setting up plants to creating
          innovative
          products. We handle everything, ensuring your business thrives financially while maintaining environmental
          sustainability. Our
          services include equipment assistance, feasibility research, design, regulatory guidance, auditing, and more –
          providing you peace
          of mind and confidence in your operations.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 sm:flex sm:flex-col md:flex md:flex-row md:ml-6">
        <div class="cardItem transform transition-transform duration-300 ease-in-out hover:scale-110 mb-4 sm:mb-0" data-aos="zoom-in" data-aos-delay="200">
          <div class="item one1">
            <span class="number">01</span>
            <a routerLink="services/turnkeyProjectSetup/turnkeyProjectSetup" (click)="scrollToTop()">
              <h5>Turnkey project setup</h5>
            </a>
            <p>
              We handle every aspect of factory design under one roof, encompassing Process, Building and
              Civil,Electrical and Automation,
              Quality Assurance, and more.Our designs adhere to global standards for food safety and hygienic
              engineering.
            </p>
          </div>
        </div>
        <div class="cardItem transform transition-transform duration-300 ease-in-out hover:scale-110 mb-4 sm:mb-0" data-aos="zoom-in" data-aos-delay="300">
          <div class="item">
            <span class="number">02</span>
            <a routerLink="services/turnkeyProjectSetup/Preparation-Of-progress-report" (click)="scrollToTop()">
              <h5>Detailed Project Report</h5>
            </a>
            <p>
              A Detailed Project Report (DPR) for a food business typically includes comprehensive information about the
              proposed venture.
              Our hand on experienced on preparation of report with expertise, financial experts ,engg team, data
              analyst leads to success.
            </p>
          </div>
        </div>
        <div class="cardItem transform transition-transform duration-300 ease-in-out hover:scale-110" data-aos="zoom-in" data-aos-delay="300">
          <div class="item">
            <span class="number">03</span>
            <a routerLink="services/newProductDevelopment" (click)="scrollToTop()">
              <h5>New Product Development</h5>
            </a>
            <p>
              Core expertise with the product development according to the FSSAI Norms we developed innovative products
              and nurturing the
              idea in mind of client. 20 + years experienced food technologist team continuously upgrade their
              knowledge.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- end services -->
<section id="clients" class="clients">
  <div class="container">
    <div class="sub-title border-bot-light w-32">Clients</div>
    <div class="row">
      <div class="wrapper1 -mt-3">
        <div class="slider">
          <div class="slide-track">
            <div *ngFor="let slideGroup of sliding" class="slide flex">
              <div *ngFor="let slide of slideGroup"
                class="relative w-[200px] h-[150px] bg-[#fefefe] rounded-md m-[15px] text-center pt-3">
                <img [src]="slide.image" alt="Slide" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="wrapper1 -mt-3">
        <div class="slider">
          <div class="slide-track">
            <div *ngFor="let slideGroup of sliding1" class="slide flex roundSlide">
              <div *ngFor="let slide of slideGroup"
                class="relative w-[200px] h-[150px] bg-[#fefefe] rounded-md m-[15px] text-center pt-3">
                <img [src]="slide.image" alt="Slide" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- end client section -->
<section id="whyus" class="py-5" style="animation-duration: 8s">
  <div class="container" data-aos="zoom-in-up">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <div class="video-row">
          <youtube-player [videoId]="videoId2" [height]="320" [width]="500" suggestedQuality="hd720"></youtube-player>
        </div>
          <div class="video-row">
          <youtube-player [videoId]="videoId1" [height]="320" [width]="500" suggestedQuality="hd720"></youtube-player>
        </div>
        
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <div>
          <section class="card-body">
            <ng-container *ngFor="let faq of faqs; let i = index" style="animation-duration: 9s">
              <div class="item1">
                <div class="accordion-header" data-aos="fade-left" data-aos-delay="100" data-aos-offset="20">
                  <button (click)="toggleAccordion(i)">
                    {{ faq.question }}
                    <span [ngClass]="{ open: i === activeIndex }"><i class="bi bi-plus-square-fill"></i></span>
                  </button>
                </div>
                <div [ngClass]="{ open: i === activeIndex }" class="accordion-body" data-aos="fade-right"
                  data-aos-delay="100" data-aos-offset="30">
                  <ul>
                    <li *ngFor="let item of faq.answers">
                      {{ item }} <a target="_blank" href="tel:+91 7218024999"><strong>Call Now</strong></a>
                    </li>
                  </ul>
                </div>
              </div>
            </ng-container>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- why we -->
<div id="program" class="container animate__animated animate__zoomIn" style="animation-duration: 10s">
  <div class="inner-container" data-aos="zoom-in">
    <div class="TS-title">
      <div class="sub-title border-bot-light w-32 ml-11">Why Us</div>
    </div>

    <div class="row tool_box">
      <div *ngFor="let tool of tools" class="col-auto tool-section">
        <div class="tool-item">
          <img [src]="tool.imageSrc" class="responsive-image" />
          <div class="tool-name">{{ tool.name }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- sector section -->
<section id="portfolio" class="portfolio">
  <div class="container" data-aos="zoom-in" data-aos-delay="400">
    <div class="sub-title border-bot-light w-80" data-aos="fade-up">Food Category We Serve</div>
    <carousel>
      <slide>
        <div class="row">
          <div class="col-lg-3 col-md-6 portfolio-item" *ngFor="let item of slides1" [interval]="2000">
            <div class="portfolio-wrap" (click)="navigateToFoodCategory(item.title)">
              <img [src]="item.imageUrl" class="img-fluid" alt="{{ item.title }}" />
              <div class="portfolio-info">
                <h4>{{ item.title }}</h4>
                <div class="portfolio-links">
                  <a href="" data-gallery="portfolioGallery" class="portfolio-lightbox" [title]="item.title"></a>
                </div>
              </div>
            </div>
            <div class="portfolio-text">
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="row">
          <div class="col-lg-3 col-md-6 portfolio-item" *ngFor="let item of slides2" [interval]="2000">
            <div class="portfolio-wrap" (click)="navigateToFoodCategory(item.title)">
              <img [src]="item.imageUrl" class="img-fluid" alt="{{ item.title }}" />
              <div class="portfolio-info">
                <h4>{{ item.title }}</h4>
                <div class="portfolio-links">
                  <a href="" data-gallery="portfolioGallery" class="portfolio-lightbox" [title]="item.title"></a>
                </div>
              </div>
            </div>
            <div class="portfolio-text">
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="row">
          <div class="col-lg-3 col-md-6 portfolio-item" *ngFor="let item of slides3" [interval]="2000">
            <div class="portfolio-wrap" (click)="navigateToFoodCategory(item.title)">
              <img [src]="item.imageUrl" class="img-fluid" alt="{{ item.title }}" />
              <div class="portfolio-info">
                <h4>{{ item.title }}</h4>
                <div class="portfolio-links">
                  <a href="" data-gallery="portfolioGallery" class="portfolio-lightbox" [title]="item.title"></a>
                </div>
              </div>
            </div>
            <div class="portfolio-text">
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="row">
          <div class="col-lg-3 col-md-6 portfolio-item" *ngFor="let item of slides4" [interval]="2000">
            <div class="portfolio-wrap" (click)="navigateToFoodCategory(item.title)">
              <img [src]="item.imageUrl" class="img-fluid" alt="{{ item.title }}" />
              <div class="portfolio-info">
                <h4>{{ item.title }}</h4>
                <div class="portfolio-links">
                  <a href="" data-gallery="portfolioGallery" class="portfolio-lightbox" [title]="item.title"></a>
                </div>
              </div>
            </div>
            <div class="portfolio-text">
              <p>{{ item.text }}</p>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</section>
<!-- testimonials -->
<app-test-page></app-test-page>
<!--  -->