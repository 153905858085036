<section class="testimonial text-center">
    <div class="container">
        <div class="heading white-heading">
            Our Key Assets
        </div>
        <div id="testimonial4"
            class="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x"
            data-ride="carousel" data-pause="hover" data-interval="5000" data-duration="2000">
            <div class="carousel-inner" role="listbox">
                <div *ngFor="let testimonial of testimonials; let i = index"
                    [ngClass]="{'carousel-item': true, 'active': i === 0}">
                    <div class="testimonial4_slide">
                        <img [src]="testimonial.img" class="img-circle img-responsive" />
                        <h4>{{testimonial.name}}</h4>
                        <h3>{{testimonial.role}}</h3>
                        <h3>{{testimonial.education}}</h3>
                        <p>{{testimonial.text}}</p>
                        <h4>
                            <a href="{{testimonial.linkdinLink}}" class="text-white" target="_blank">{{testimonial.linkdinLink}}</a>
                          </h4>
                          
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev text-decoration-none" href="#testimonial4" role="button"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="carousel-control-next text-decoration-none" href="#testimonial4" role="button"
                data-bs-slide="next">
                <span class="carousel-control-next-icon"></span>
            </a>
        </div>
    </div>
</section>