<div class="container-fluid">
  <div class="row">

    <div class="col-sm-3">
      <div class="lftcolumn animate__animated animate__zoomInUp">
        <ul>
          <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
          <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
          <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory
              Services</a></li>
          <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
          <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
          <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
          <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training Services</a>
          </li>
          <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
        </ul>
      </div>
    </div>

    <div class="col-sm-9 title">
      <h1 class="animate__animated animate__backInDown">New Product Development</h1>

      <div class="row innercontent">
        <div class="col-sm-12">
          <p class="text-justify animate__animated animate__backInLeft">
            <img src="../../../assets/sector/New Product Development (2).jpg"
              class="pull-right img-responsive img-thumbnail marginlft20 animate__animated animate__zoomIn" alt="" />
            Cantered on product development and improvement, our core focus is deeply rooted
            in comprehending the consumer's viewpoint and ensuring their utmost
            satisfaction. We extend our services and expertise throughout diverse stages of
            product development, boasting a dedicated and skilled team that brings a wealth
            of knowledge to the table. As experts in the food industry, we excel not only in
            crafting innovative new products but also in elevating the quality and appeal of
            existing offerings in the market. Our primary goal remains unwavering – to
            enhance product quality, enabling wider market penetration and achieving
            heightened levels of consumer satisfaction.<br />
            In the realm of consultancy services
            for new product development, our focus is on providing strategic guidance and
            expertise throughout the entire process. We specialize in market research,
            concept development, and feasibility assessment, ensuring our clients navigate
            the complexities of launching innovative products with confidence. Our
            consultancy aims to optimize the development cycle, from idea generation to
            market launch, fostering successful outcomes for our clients in the dynamic
            landscape of new product development.
          </p>

          <ul class="nolisting animate__animated animate__lightSpeedInLeft">
            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i> Idea Generation & Nurturing
            </li>

            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i>Market Research
            </li>

            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i>Concept Development
            </li>

            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i> Competitors research
            </li>

            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i>
              Raw Material Arrangement
            </li>
            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i>
              Lab Scale trial
            </li>
            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i>
              Product Testing and Validation
            </li>
            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i>
              Prototyping and Design
            </li>
            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i>
              Regulatory Compliance
            </li>
            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i>
              Cost Estimation
            </li>
            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i>
              Documentation
            </li>
            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i>
              Shelf-Life Analysis
            </li>
            <li>
              <i class='bx bxs-hand-right' aria-hidden="true"></i>
              Nutritional analysis & Claims
            </li>
          </ul>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
<!--  -->