import { Component } from '@angular/core';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss'],
})
export class TestPageComponent {
  constructor() { }

  testimonialsData = [
    {
      id: 1,
      author: '',
      company: 'Taucies Food (Owner)',
      image: '../../assets/testimonials/test2.jpg',
      content: "FoodCognics Team has exceptional knowledge in the field of Frozen RTE Plant setup and commissioning. His pragmatic behavior has helps us in setting up a world-class level facility within a 5000 sqft area. He is always ready to share his real-life experience as a result we developed a very close relationship.Even after the assignment completion he still continues to guide us on various aspect like Brand development."
    },
    {
      id: 2,
      author: 'Dr. Rajendra (Director)',
      company: 'Ramzi Foods',
      image: '../../assets/testimonials/Test1.jpg',
      content:
        "We have been associated with Food Cognics since 4 years. They helpedus for new product development and formulation, contract manufacturing identification and production assistance. We are happy with the services provided by FoodCognics. Awesome team spirit,... Really appreciated."
    },
    {
      id: 3,
      author: 'Mr. Aditya Karanikar (Director)',
      company: 'Mallows Food',
      image: '../../assets/testimonials/test3.jpg',
      content:"I was looking for startup the new manufacturing plant for fruits and vegetable processing. FoodCognics provided the right solution and guided us how to enter in food sector without investing on Machinery. We launched the products through contact manufacturing under the guidance of FC team."
    },
   
    {
      id: 4,
      author: '',
      company: 'MESMERIK FOOD PRODUCTS',
      image: '../../assets/Services-Images/mesmerikLogoFood.jpg',
      content:
        "We appreciate FOODCOGNICS team for the outstanding service provided by. We were searching for the professional consultant for our manufacturing unit of MESMERIK FOOD PRODUCTS for FSSAI Central License. We pleased with the knowledge and skill of your staff. The staff was incredibly friendly & helpful. Great service makes your customers feel that you care about developing a long-term relationship.I would love to recommend your company to anyone who needs service in food technology and services and I will surely take your relationship for long term.",
    },
    {
      id: 5,
      author: 'Mr. Vijaykumar Chole',
      company: 'vice President Satara Mega Food Park',
      image: '../../assets/about/parkImg.jpg',
      content:" FoodCognics has an incredible gift for making the complex appear simple and without being simplistic. Team of FoodCognics is a pleasure to work with and always delivers to an exceptionally high standard as well as consults impeccably to understand business / startups needs and then brings innovative business relevant ideas and solutions to the table. Mr.Gajanan Kalyane will challenge your thinking, provide you with alternative perspectives and, where appropriate, actively.",
    },
    {
      id: 6,
      author: '(Co-Founder-Rooted Peepul)',
      company: 'Sheeza Kazmis',
      image: '../../assets/Services-Images/mesmerikLogoFood.jpg',
      content:" Our experience with FoodCognics has been very good. Being a new brand, we had a lot of questions and doubts, but everyone from FoodCognics were patient enough to answer our smallest of queries. Ganesh, Manisha, Dhanshree and all other people had the total knowledge of the food Industry.From Consultation on Stability, solubility of the product to Providing the alternatives to the ingredients, to the labelling suggestions as per the FSSAI guidelines, they did everything very professionally. Totally happy with the team at FoodCognics."
      },
    {
      id: 7,
      author: 'Yash Bhanage (Director)',
      company: 'Hunger Inc Hospitality Private Limited',
      image: '../../assets/about/9testimonial.jpeg',
      content:' We wanted to take a moment to provide feedback on the exceptional services provided by FoodCognics India Private Limited during License services including FSSAI, LEGAL METROLOGY. FoodCognics India Private Limited consistently demonstrated exceptional expertise and professionalism in food regulation with in-depth knowledge of the food industry and genuine passion for helping us succeed.The consultation process provided invaluable guidance, addressing our unique needs significantly contributing to the success of our work. We look forward to maintaining this collaborative relationship.Thank you again for your outstanding support and expertise.',
    },
    {
      id: 8,
      author: '',
      company: 'IndiaQO Foods Pvt. Ltd',
      image: '../../assets/testimonials/India.jpeg',
      content:"We would like to appreciate Mr. Gajanan Kalyane for his contribution as Lead Project Consultant for setting up state of art Greenfield project of processing of sweet corn, fruits and vegetables with capacity 2MT/HR and 1500MT modern cold store. This project executed between March 2022-August 2023. He also helped us for finalizing contract manufacturing deal of around 100-150MT/Month. All the best of his future projects.",
    },
    {
      id: 9,
      author: '',
      company: 'NextOn Foods Pvt Ltd',
      image: '../../assets/testimonials/nexton.jpeg',
      content:'  We "NextOn Foods Pvt Ltd" would like to express our pleasure in regards to the quality service provided by FoodCognics India Pvt Ltd during our finding of best plant which is suitable for us to get Quality products. Your Professional & quick response helps us to setting up the right timeline & estimate for getting world class facility is surrounding area of Pune. Once again, thank you sincerely for your drive and hard work. We look forward to your future success with FoodCognics India Pvt Ltd!"'
    },
  ];

  ngOnInit(): void { }
}
