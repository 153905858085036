<section id="services" class="service;">
  <div class="container">
    <h3 class="animate__animated animate__fadeInLeft"><strong>Training to Graduates</strong></h3>
    <div *ngFor="let service of services; let i = index" class="row first">
      <div class="col" [ngClass]="{ 'order-md-2': i % 2 !== 0 }">
        <img [src]="service.image" alt="Image" class="animate__animated animate__zoomIn" />
      </div>
      <div class="col" [ngClass]="{ 'order-md-1': i % 2 !== 0 }">
        <h2 class="animate__animated animate__fadeInRight"><strong>{{ service.caption }}</strong></h2>
        <p class="animate__animated animate__slideInRight">{{ service.content }}</p>
      </div>
    </div>
  </div>

  <!-- FORM -->
   
  <div class="offset-md-1" style="background-color: aliceblue;">
    <div class="texts">
      <div class="">
        <br><br>
        <h3 class="text-start font-bold margin-top: 82px; margin-left: 90px;">Opportunity</h3>
        <form name= "editForm" class="form" role="form" novalidate (ngSubmit)="onSubmit()" [formGroup]="editForm">
          <div class="col-md-12 form-group inputWithIcon">

            <input type="text" name="name" class="form-control bi bi-person" id="name" formControlName="name"
              placeholder="Your Name" />
             <i class = "fa fa-user fa-lg fa-fw" aria-hidden = "true"> </i> 
             <div *ngIf="
                editForm.get('name')!.invalid &&
                (editForm.get('name')!.dirty || editForm.get('name')!.touched)
              ">
              <small class="form-text text-danger" *ngIf="editForm.get('name')?.errors?.['required']">
                This field is required.
              </small>
            </div>
          </div>

          <div class="col-md-12 form-group inputWithIcon">
            <input type="text" name="email" class="form-control" id="email" formControlName="email"
              placeholder="Your Email" />
              <i class="fa fa-envelope fa-lg fa-fw" style="font-size: 13px"></i>
              <div *ngIf="
                editForm.get('contactNumber')!.invalid &&
                (editForm.get('email')!.dirty || editForm.get('email')!.touched)
              ">
              <small class="form-text text-danger" *ngIf="editForm.get('email')?.errors?.['required']">Email is
                required</small>
              <small class="form-text text-danger" *ngIf="editForm.get('email')?.errors?.['email']">Email must
                be a valid
                email address.</small>
            </div>
          </div>

          <div class="col-md-12 form-group inputWithIcon">
            <input type="text" name="contactNumber" class="form-control" id="contactNumber"
              formControlName="contactNumber" placeholder="Your Number" />
              <i class="fa fa-phone fa-lg fa-fw" style="font-size: 17px"></i>
            <div *ngIf="
                editForm.get('contactNumber')!.invalid &&
                (editForm.get('contactNumber')!.dirty ||
                  editForm.get('contactNumber')!.touched)
              ">
              <small class="form-text text-danger" *ngIf="editForm.get('contactNumber')?.errors?.['required']">
                This field is required.
              </small>
              <small class="form-text text-danger"
                *ngIf="editForm.get('contactNumber')?.errors?.['pattern']">Please enter
                a 10-digit Mobile Number</small>
            </div>
          </div>

          <div class="col-md-12 form-group">
            <textarea class="form-control" name="requirements" rows="2" placeholder="Requirements"
              formControlName="requirements"></textarea>
              
              <!-- <i class="bi-pin"></i> -->
            <div
              *ngIf="editForm.get('requirements')!.invalid && (editForm.get('requirements')!.dirty || editForm.get('requirements')!.touched)">
              <small class="form-text text-danger"
                *ngIf="editForm.get('requirements')?.errors?.['required']">This field is required</small>
            </div>
          </div>

          <div class="col-md-12 form-group">
            <label>Your CV</label>
            <input type="file" class="form-control" name="cv" rows="2" placeholder="Requirements"
              formControlName="cv"/>
              <!-- <i class="bi-pin"></i> -->
            <div
              *ngIf="editForm.get('requirements')!.invalid && (editForm.get('requirements')!.dirty || editForm.get('requirements')!.touched)">
              <small class="form-text text-danger"
                *ngIf="editForm.get('requirements')?.errors?.['required']">This field is required</small>
            </div>
          </div>

          <div *ngIf="showThankYouMessage" class="text-center text-success">
            Thank You for contacting us. Our team will get back to you shortly.
          </div>

          <div class="col-md-12 form-group">
            <button type="button" class="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium 
            rounded-lg text-sm px-5 py-2.5 me-2 mb-2 mt-3 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-700">Book</button>
            <!-- <button class=" btn text-red hover:before:bg-redborder-red-500 relative h-[50px] w-28 ml-40 mt-3
             overflow-visible border border-blue-600 bg-blue-600 px-6 text-white shadow-sm transition-all 
             before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-blue-400 
             before:transition-all before:duration-400 hover:text-black hover:shadow-blue-400 hover:before:left-0 
             hover:before:w-full"><span class="relative z-10">Book</span></button> -->

          </div>
        </form> 
        <br>

        <!-- <div id="success-popup" class="popup">
          <div class="popup-content">
            <p>Form submitted successfully!</p>
            <button id="close-popup">Close</button> 
          </div>
        </div> -->

      </div>
   </div>
</div>
