
<div class="container" id="program">
    <div class="inner-container">
      <div class="TS-title">
        <strong>Food start up program </strong>
        <p>12 Month Handling Support</p>
      </div>
      <p class="text-big mb-5 UI_para"></p>
      <div class="row tool_box  animate__animated animate__bounce">
        <div class="col-auto animate__animated animate__backInDown" style="animation-duration: 1s;">
          <div class="tool-name hover:scale-110">
            <img src="../../assets/foodProcess/food1.png" alt=" " class="me-2" />
            Food concept selection <br />Assisting the new product <br />selection
            as per the <br />current trend in the market.
          </div>
        </div>
        <div class="col-auto animate__animated animate__backInUp" style="animation-duration: 1.5s;">
          <div class="tool-name hover:scale-110">
            <img src="../../assets/foodProcess/food2.png" alt=" " class="me-2" />
            New Product development /<br />
            Formulation Innovative & <br />
            unique product development <br />services.
          </div>
        </div>
        <div class="col-auto animate__animated animate__bounceInDown" style="animation-duration: 2s;">
          <div class="tool-name hover:scale-110">
            <img
              src="../../assets/foodProcess/food3.png"
              alt=" Icon"
              class="me-2"
            />
            Contract Manufacturing Identification <br />
            Assist in contract manufacturer<br />
            selection with respect to<br />
            product category, project capacity.
          </div>
        </div>
        <div class="col-auto animate__animated animate__bounceInUp" style="animation-duration: 2.5s;">
          <div class="tool-name hover:scale-110" style="padding: 5px 12px">
            <img
              src="../../assets/foodProcess/food4.jpg"
              alt="Angular Icon"
              class="me-2"
            />
            Packaging Design and <br />
            Branding Packaging design <br />and branding with in-house
            <br />highly skilled food <br />packaging designers.
          </div>
        </div>
        <div class="col-auto animate__animated animate__backInDown" style="animation-duration: 3s;">
          <div class="tool-name hover:scale-110">
            <img
              src="../../assets/foodProcess/food5.jpg"
              alt=" Icon"
              class="me-2"
            />
            Food Packaging Solutions <br />Provide current packaging trends<br />
            & assistance in selection of<br />
            packaging for designated<br />
            product category.
          </div>
        </div>
        <div class="col-auto animate__animated animate__flipInY" style="animation-duration: 3.5s;">
          <div class="tool-name hover:scale-110">
            <img
              src="../../assets/foodProcess/food6.jpg"
              alt=" Icon"
              class="me-2"
            />
            Scale Up Trials /Food <br />Industry Implementation Help <br />client
            to go from lab<br />
            to market with scale up<br />
            trials of new products.
          </div>
        </div>
        <div class="col-auto animate__animated animate__backInUp" style="animation-duration: 4s;">
          <div class="tool-name hover:scale-110" style="padding: 23px 10px">
            <img
              src="../../assets/foodProcess/food7.png"
              alt=" Icon"
              class="me-2"
            />
            Government License Provide <br />all mandatory licenses for setting<br />
            up own plant/contract<br />
            manufacturing/marketing of products.
          </div>
        </div>
      </div>
    </div>
  </div>