import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuoteModalService } from './quote-modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  isMenuOpen = false;
  showThankYouMessage = false;
  isSaving = false;

  turnkeyProjectServices = [
    {
      name: "Turnkey Project Setup",
      link: "services/turnkeyProjectSetup/turnkeyProjectSetup",
      subServices: [
        {
          name: "Land Selection",
          link: "services/turnkeyProjectSetup/Land-Selection",
        },
        {
          name: "Basis of Design",
          link: "services/turnkeyProjectSetup/basis-of-design",
        },
        {
          name: "Architectural Service",
          link: "services/turnkeyProjectSetup/Architectural-Service",
        },
        {
          name: "Building Design Phase",
          link: "services/turnkeyProjectSetup/Building-Design-Phase",
        },
        {
          name: "Machinery Selection",
          link: "services/turnkeyProjectSetup/Machinery-Selection",
        },
        {
          name: "Preparation of Implementation Schedule",
          link: "services/turnkeyProjectSetup/Preparation-of-Implementation-Schedule",
        },
        {
          name: "Vendor Selection & Management",
          link: "services/turnkeyProjectSetup/Vendor-Selection-&-Management",
        },
        {
          name: "Site supervision",
          link: "services/turnkeyProjectSetup/Site-supervision",
        },
        {
          name: "Progress Report",
          link: "services/turnkeyProjectSetup/Preparation-Of-progress-report",
        },
        {
          name: "Value Engineering",
          link: "services/turnkeyProjectSetup/Value-Engineering",
        },
        {
          name: "Master Project Budget preparation",
          link: "services/turnkeyProjectSetup/Master-Project-Budget-preparation",
        },
        {
          name: "Documents & Handover Procedures",
          link: "services/turnkeyProjectSetup/Documents-&-Handover-Procedures",
        },
      ],
    },
  ];

  mandatoryServices = [
    {
      name: "FSSAI",
      link: "services/licensingRegulatoryServices/mandatory/FSSAI",
    },
    {
      name: "Legal Metrology",
      link: "services/licensingRegulatoryServices/mandatory/Legal-metrology-act",
    },
    {
      name: "Vegan Product Approved By FSSAI",
      link: "services/licensingRegulatoryServices/mandatory/Vegan-Product-Approved-By-FSSAI",
    },
    {
      name: "Spice Board",
      link: "services/licensingRegulatoryServices/mandatory/Spice-Board",
    },
    {
      name: "APEDA",
      link: "services/licensingRegulatoryServices/mandatory/APEDA",
    },
    {
      name: "Factory Act",
      link: "services/licensingRegulatoryServices/mandatory/Factory-Act",
    },
    {
      name: "MPCB",
      link: "services/licensingRegulatoryServices/mandatory/MPCB",
    },
    {
      name: "Land Acquisition (MIDC)",
      link: "services/licensingRegulatoryServices/mandatory/Land-Acquisition",
    },
  ];

  voluntaryServices = [
    {
      name: "Import Export Clearance/Code Certification",
      link: "services/licensingRegulatoryServices/Certifications/Import-Export-certificate",
    },
    {
      name: "Organic Certification",
      link: "services/licensingRegulatoryServices/Certifications/Organic-Certification",
    },
    {
      name: "ISO Certification",
      link: "services/licensingRegulatoryServices/Certifications/ISO-Certification",
    },
    {
      name: "FSSC Certification",
      link: "services/licensingRegulatoryServices/Certifications/FSSC-Certification",
    },
    {
      name: "HALAL",
      link: "services/licensingRegulatoryServices/Certifications/HALAL",
    },
    {
      name: "USFDA",
      link: "services/licensingRegulatoryServices/Certifications/USFDA",
    },
    {
      name: "KOSHER",
      link: "services/licensingRegulatoryServices/Certifications/Kosher",
    },
    {
      name: "BRC",
      link: "services/licensingRegulatoryServices/Certifications/BRC",
    },
    {
      name: "BIS",
      link: "services/licensingRegulatoryServices/Certifications/BIS",
    },
  ];

  financialServices = [
    { name: "DPR", link: "services/financialServices/DPR" },
    {
      name: "Feasibility Study Report",
      link: "services/financialServices/Feasibility-Study-Report",
    },
    {
      name: "Techno Economic Viability Study Report",
      link: "services/financialServices/Techno-Economic-Viability-Study-Report",
    },
  ];

  governmentServices = [
    { name: "MoFPI", link: "services/governmentSchemas/MoFPI" },
    { name: "MSME", link: "services/governmentSchemas/MSME" },
    { name: "APEDA", link: "services/governmentSchemas/APEDA" },
    { name: "NHB", link: "services/governmentSchemas/NHB" },
    { name: "DAHD", link: "services/governmentSchemas/DAHD" },
  ];

  auditingServices = [
    {
      name: "Food Safety Audits",
      link: "services/auditingTrainingServices/Food-Safety-Audits",
    },
    {
      name: "Manufacturing Audits",
      link: "services/auditingTrainingServices/Manufacturing-Audits",
    },
    {
      name: "Vendor Audit",
      link: "services/auditingTrainingServices/Vendor-Audit",
    },
    {
      name: "Organic Certification Training",
      link: "services/auditingTrainingServices/Organic-Certification-Training",
    },
    {
      name: "Energy Audit",
      link: "services/auditingTrainingServices/Energy-Audit",
    },
  ];

  valueServices = [
    {
      name: "Monthly Advisory Services",
      link: "services/valueAddedService/Monthly-Advisory-Services",
    },
    {
      name: "Contract Manufacturing",
      link: "services/valueAddedService/Contract-Manufacturing",
    },
    {
      name: "Production Assistance Service",
      link: "services/valueAddedService/Production-Assistance-Service",
    },
    {
      name: "Merger & Acquisition",
      link: "services/valueAddedService/Merger-&-Acquisition",
    },
  ];

  blogItems = [
    {
      title: '"Seal the Taste, Transform the Future:"',
      title1: "Canning Innovations at Your Table.",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7153273948791595008",
    },
    {
      title: "Food Industry Drains: The",
      title1: "Food Safety Auditors Favorite.",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7146909907961778176?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7146909907961778176%29",
    },
    {
      title: "5 Bottlenecks in Indian",
      title1: "Food Processing Industries.",
      link: "https://www.linkedin.com/pulse/5-bottlenecks-indian-food-processing-industries-gajanan-kalyane?utm_source=share&utm_medium=member_android&utm_campaign=share_via",
    },
    {
      title: "10 services which all",
      title1: "agro-food industry needs.",
      link: "https://www.linkedin.com/pulse/10-services-which-all-agro-food-industry-needs-gajanan-kalyane?utm_source=share&utm_medium=member_android&utm_campaign=share_via",
    },

    {
      title: "FSSAI Label Claim ",
      link: "https://foodcognics.blogspot.com/2024/02/fssai-label-claims.html",
    },

    {
      title: "Vegan Food Products ",
      link: "https://foodcognics.blogspot.com/2024/02/vegan-food-products.html",
    },
    {
      title: "Reducing Food Waste- A Call to Action ",
      link: "https://foodcognics.blogspot.com/2024/03/reducing-food-waste-call-to-action.html",
    },
    {
      title: "The IQF-Future of Food Industry",
      link: "https://foodcognics24.blogspot.com/2024/03/iqf-future-of-food-industry-detailed.html",
    },
    {
      title: "Transforming Food Quality &",
      title1: "Administrative Efficiency :- Case Study.",
      link: "https://foodqualitycasestudybytanishqsajankar.blogspot.com/2024/08/transforming-food-quality.html",
    },
  ];

  services = [
    {
      name: "Turnkey Project Setup",
      children: [
        {
          name: "Land Selection",
          routerLink: "services/turnkeyProjectSetup/Land-Selection",
        },
        {
          name: "Basis of Design",
          routerLink: "services/turnkeyProjectSetup/basis-of-design",
        },
        {
          name: "Architectural Service",
          routerLink: "services/turnkeyProjectSetup/Architectural-Service",
        },
        {
          name: "Building Design Phase",
          routerLink: "services/turnkeyProjectSetup/Building-Design-Phase",
        },
        {
          name: "Machinery Selection",
          routerLink: "services/turnkeyProjectSetup/Machinery-Selection",
        },
        {
          name: "Preparation of Implementation Schedule",
          routerLink:
            "services/turnkeyProjectSetup/Preparation-of-Implementation-Schedule",
        },
        {
          name: "Vendor Selection & Management",
          routerLink:
            "services/turnkeyProjectSetup/Vendor-Selection-&-Management",
        },
        {
          name: "Site supervision",
          routerLink: "services/turnkeyProjectSetup/Site-supervision",
        },
        {
          name: "Progress Report",
          routerLink:
            "services/turnkeyProjectSetup/Preparation-Of-progress-report",
        },
        {
          name: "Value Engineering",
          routerLink: "services/turnkeyProjectSetup/Value-Engineering",
        },
        {
          name: "Master Project Budget preparation",
          routerLink:
            "services/turnkeyProjectSetup/Master-Project-Budget-preparation",
        },
        {
          name: "Documents & Handover Procedures",
          routerLink:
            "services/turnkeyProjectSetup/Documents-&-Handover-Procedures",
        },
      ],
    },
    {
      name: "New Product Development",
      routerLink: "services/newProductDevelopment",
    },
    {
      name: "Licensing & Regulatory Services",
      children: [
        {
          name: "FSSAI",
          routerLink: "services/licensingRegulatoryServices/mandatory/FSSAI",
        },
        {
          name: "Legal Metrology",
          routerLink:
            "services/licensingRegulatoryServices/mandatory/Legal-metrology-act",
        },
        {
          name: "Vegan Product Approved By FSSAI",
          routerLink:
            "services/licensingRegulatoryServices/mandatory/Vegan-Product-Approved-By-FSSAI",
        },
        {
          name: "Spice Board",
          routerLink:
            "services/licensingRegulatoryServices/mandatory/Spice-Board",
        },
        {
          name: "APEDA",
          routerLink: "services/licensingRegulatoryServices/mandatory/APEDA",
        },
        {
          name: "Factory Act",
          routerLink:
            "services/licensingRegulatoryServices/mandatory/Factory-Act",
        },
        {
          name: "MPCB",
          routerLink: "services/licensingRegulatoryServices/mandatory/MPCB",
        },
        {
          name: "Land Acquisition (MIDC)",
          routerLink:
            "services/licensingRegulatoryServices/mandatory/Land-Acquisition",
        },
      ],
    },
    {
      name: "IT Enabled Food Industry",
      routerLink: "services/technicalServices",
    },
    {
      name: "Government schems & Subsidy",
      children: [
        { name: "MoFPI", routerLink: "services/governmentSchemas/MoFPI" },
        { name: "MSME", routerLink: "services/governmentSchemas/MSME" },
        { name: "APEDA", routerLink: "services/governmentSchemas/APEDA" },
        { name: "NHB", routerLink: "services/governmentSchemas/NHB" },
        { name: "DAHD", routerLink: "services/governmentSchemas/DAHD" },
      ],
    },
    {
      name: "Auditing & Training Services",
      children: [
        {
          name: "Food Safety Audits",
          routerLink: "services/auditingTrainingServices/Food-Safety-Audits",
        },
        {
          name: "Manufacturing Audits",
          routerLink: "services/auditingTrainingServices/Manufacturing-Audits",
        },
        {
          name: "Vendor Audit",
          routerLink: "services/auditingTrainingServices/Vendor-Audit",
        },
        {
          name: "Organic Certification Training",
          routerLink:
            "services/auditingTrainingServices/Organic-Certification-Training",
        },
        {
          name: "Energy Audit",
          routerLink: "services/auditingTrainingServices/Energy-Audit",
        },
      ],
    },
    {
      name: "Value Added Service",
      children: [
        {
          name: "Monthly Advisory Services",
          routerLink: "services/valueAddedService/Monthly-Advisory-Services",
        },
        {
          name: "Contract Manufacturing",
          routerLink: "services/valueAddedService/Contract-Manufacturing",
        },
        {
          name: "Production Assistance Service",
          routerLink:
            "services/valueAddedService/Production-Assistance-Service",
        },
        {
          name: "Merger & Acquisition",
          routerLink: "services/valueAddedService/Merger-&-Acquisition",
        },
      ],
    },
  ];

  editForm = new FormGroup({
    name: new FormControl(null, {
      validators: [Validators.required, Validators.maxLength(50)],
    }),
    contactNumber: new FormControl(null, {
      validators: [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      ],
    }),
    email: new FormControl(null, {
      validators: [Validators.required, Validators.email],
    }),
    requirements: new FormControl(null, {
      validators: [Validators.required, Validators.maxLength(100)],
    }),
  });

  constructor(
    public router: Router,
    private quoteModalService: QuoteModalService
  ) { }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  closeMenu(): void {
    this.isMenuOpen = false;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  save(): void {
    this.isSaving = true;
    const formDetails = this.editForm.getRawValue();
    this.quoteModalService.modelQuote(formDetails).subscribe({
      next: () => this.onSaveSuccess(),
      error: () => this.onSaveError(),
    });
  }

  private onSaveSuccess(): void {
    this.editForm.reset();
    this.showThankYouMessage = true;
    setTimeout(() => {
      this.showThankYouMessage = false;
    }, 5000);
  }

  private onSaveError(): void {
    this.isSaving = false;
  }
}
