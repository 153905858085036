<div class="container">
  <div class="row mb-30">
    <div class="col-md-3">
      <div class="sub-title border-bot-light">{{ category }}</div>
    </div>
  </div>

  <ng-container *ngIf="category === 'Functional-Food'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/Standardised Food Product.jpg" alt="Image Description"
          class="max-w-full rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
          India is dominating the South Asia functional food and natural health
          products market holding around 32% share in 2023, and it is expected to
          grow with a CAGR of 4.2% over the forecast period. India is a prominent
          developing nation with intense economic growth with an increasing
          middle-class population. This middle-class consumer base is spending a
          high amount on healthy foods and nutritional supplements. Thus, the
          demand for functional and natural health products is increasing,
          propelling growth in the functional food and natural health products
          market. Functional Foods are normally classified as Bodybuilding Foods,
          Energy Yielding Foods (carbohydrates), Protein Rich Foods, Protective
          Foods (fruits and vegetables) etc.
        </p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="category === 'Fruits-&-Vegetable'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/Fruits and Vegetables by foodcognics.jpg" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p>Key Points are: </p>
        <strong> <i class='bx bxs-circle mx-2'></i>Fruit<br />
          <i class='bx bxs-circle mx-2'></i>Vegetables<br />
          <br /></strong>
        <p>
          India's diverse climate ensures the availability of all varieties of
          fresh fruits & vegetables.
          It ranks second in fruits and vegetable production in the world, after
          China. <br /><br/>
          As per National Horticulture Database (3rd Advance Estimates)
          published by National Horticulture Board, during 2021-22, India
          produced 107.24 million metric tonnes of fruits and 204.84 million
          metric tonnes of vegetables.According to FAO (2021), India is the
          largest producer of ginger and okra among vegetables and ranks second
          in the production of Potatoes, Onions, Cauliflowers, Brinjal,
          Cabbages, etc.
          <br /> <br />
          Amongst fruits, the country ranks first in the production of Bananas
          (26.45%), Mangoes (including mangosteens and guavas) (43.80%)and
          Papayas (39.30%).<br />The vast production base offers India
          tremendous opportunities for export. During 2022-23, India exported
          fresh fruits and vegetables worth Rs. 13185.30 crores/ 1635.95 USD
          Millions which comprised Fresh Fruits worth Rs. 6,219.46 crores/
          770.70 USD Millions and vegetables worth Rs. 6,965.83 crores/ 865.24
          USD Millions. Grapes, Pomegranates, Mangoes, Bananas, and Oranges
          account for the larger portion of fruits exported from the country
          while Onions, Mixed Vegetables, Potatoes, Tomatoes, and Green Chilly
          contribute largely to the vegetable export basket.Major destinations
          for the Indian Fresh Fruits and Vegetables are U Arab Emts, ,
          Bangladesh, Nepal, Malaysia, Netherland, Sri Lanka, U.k, Qatar, Oman,
          and Iraq.<br />Major destinations for the Indian Processed Fruits and
          Vegetables are USA, UAE, Bangladesh, China, Saudi Arab and
          Netherland.<br /><br />
          In fruits and vegetable sector the wide variety of products are
          involved including jams, ketchups, chutneys, sausces, pickles, juices,
          pulps, non-alcoholic beverages etc. these products covers major
          market.
        </p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="category === 'Cereal-Products'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/cereals.jpg" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p><strong>Key Points are: </strong></p>
        <strong> <i class='bx bxs-circle mx-2'></i>Whole, broken, or flaked
          grain<br />
          <i class='bx bxs-circle mx-2'></i>Breakfast cereals<br />
          <i class='bx bxs-circle mx-2'></i>Pastas and noodles<br />
          <i class='bx bxs-circle mx-2'></i>Soybean products<br />
          <i class='bx bxs-circle mx-2'></i>Pre-cooked or processed cereal/grain/legume<br />
          <i class='bx bxs-circle mx-2'></i>Flours and starches
          <br /><br /></strong>
        <p>
          India is the world's second-largest producer of Rice, Wheat, and
          other cereals. The important cereals are - wheat, paddy, sorghum,
          millet (Bajra), barley, and maize, etc. India is the largest
          producer as well as the largest exporter of cereal products in the
          world. India's export of cereals stood at Rs. 111,062.37 Crore
          during the year 2022-23. Rice (including Basmati and Non-Basmati)
          occupy the major share in India's total cereals export with 80% (in
          value terms) during the same period. Whereas, other cereals
          including wheat represent only a 20 % share of total cereals
          exported from India during this period. Major Export Destinations
          (2022-23): Bangladesh, Saudi Arabia, Iran, U Arab Emts, and Benin.
        </p>
      </div>
    </div>
  </ng-container>

  <!--  -->
  <ng-container *ngIf="category === 'Confectionary-Products'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/Cocoa.png" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p><strong>Key Points are: </strong></p>
        <strong> <i class='bx bxs-circle mx-2'></i>Cocoa products and chocolate
          products<br />
          <i class='bx bxs-circle mx-2'></i>Confectionery including hard
          and soft candy<br />
          <br /></strong>
        <p>
          Foods high in sugar and carbs are referred to as confections.
          Chocolates, biscuits, bars, candies, mints, and other confections are
          among the items available. The behaviors, interests, and preferences
          of consumers are always changing. This has resulted in confectionary
          innovation, which is driving market expansion. The global
          confectionery market size was worth around USD 206.53 Billion in 2022
          and is estimated to grow to about USD 261.7 Billion by 2030, with a
          compound annual growth rate (CAGR) of approximately 3.9 percent over
          the forecast period. ood segments is expected to witness double digit
          growth in coming years.
        </p>
      </div>
    </div>
  </ng-container>
  <!--  -->
  <ng-container *ngIf="category === 'Meat-Polutry'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/MeatPolutry.png" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p><strong>Key Points are: </strong></p>
        <strong> <i class='bx bxs-circle mx-2'></i>Fresh / frozen / chilled /
          ground meat and poultry<br />
          <i class='bx bxs-circle mx-2'></i>Processed comminuted meat and
          poultry products<br />
          <i class='bx bxs-circle mx-2'></i>Processed comminuted meat and
          poultry products<br />
          <i class='bx bxs-circle mx-2'></i>Edible casings<br />
          <br /></strong>
        <p>
          Animal Products play an important role in the socio-economic life of
          India. It is a rich source of high-quality animal products such as
          milk, meat and eggs. India accounts for about 7.25 percent of the
          Global Egg Production in 2021 and has. Exports of animal products
          represent a significant contribution to the Indian Agriculture sector.
          The export of Animal Products includes Buffalo meat, Sheep/ Goat meat,
          Poultry products, Animal Casings, Milk and Milk products and Honey,
          etc.India's exports of Animal Products in 2022-23 was Rs. 32,597.39
          Crores/ 4,062.15 USD Millions in,which include the major products like
          Buffalo Meat (Rs. 25648.10 Crores/ 3194.70 USD Millions), Sheep/ Goat
          Meat (Rs. 537.18 Crores/ 66.92 USD Millions), Other Meat (Rs. 16.93
          Crores/ 2.18 USD Million), Poultry Products (Rs. 1081.62 Crores/
          134.04 USD Millions), Dairy Products (Rs. 2269.85 Crores/ 284.65 USD
          Millions), Animal Casing (Rs. 326.02 Crores/ 40.87 USD Millions),
          Processed Meat (Rs. 11.72 Crores/ 1.47 USD Millions), Casein (Rs.
          816.32 Crores/ 101.19 USD Million), Albumin (Eggs & Milk (Rs. 266.88
          Crores/ 33.06 USD Millions)), and Natural Honey (Rs. 1622.77 Crore/
          203.07 USD Millions). The demand for Indian buffalo meat in the
          international market has sparked a sudden increase in meat exports.
          Buffalo meat dominated the exports with a contribution of over 78.65%
          in total Animal Products export from India in 2022-23. The main
          markets for Indian Buffalo Meat and other animal products are
          Malaysia, Vietnam Social Republic, Egypt Arab Republic, Indonesi and U
          Arab Emts.
        </p>
      </div>
    </div>
  </ng-container>

  <!--  -->
  <ng-container *ngIf="category === 'Milk-&-Dairy-Products'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/dairy 2.png" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p>
          The Indian dairy sector is the largest globally, contributing 23% to
          global milk production. The dairy sector contributes up to 4% of India's
          GDP. It is one of the finest ways for Indian farmers to get extra money,
          making it one of the most well-liked occupations in rural areas
          nationwide. It has been practiced for many years throughout India,
          leading to India providing 20% of the world's milk supply. Milk is used
          as the industry's primary raw material, and its finished products
          include butter, cheese, cream, condensed milk, dried milk, packaged
          milk, ice cream, etc.In 2022, India's dairy sector was worth INR
          14,899.8 billion, and it aims to build a $355 billion industry by 2025.
          Let's look at the biggest players in India's dairy sector. The milk
          output in India is predicted to increase to 108 million tonnes by 2025.
          In the next four to five years, there is a ton of room for growth in the
          milk processing and manufacturing plant.India has emerged as the largest
          producer of milk with a 22.76 percent share in Total Milk Production in
          the world in 2021. The India has largest population of milch animals in
          the world, with 109.85 million buffaloes, 148.88 million goats and 74.26
          million sheeps in 2019. 
        </p>
      </div>
    </div>
  </ng-container>
  <!--  -->
  <ng-container *ngIf="category === 'Instant-Premixes'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/instant-primixes.jpg" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p>
          Instant mixes comprise of a mixture of processed cereals, pulses,
          condiments, spices or other foods in varying combinations. it includes
          Gravy Mixes,Ethnic Food Premix,Instant Milk Shakes,Instant Soup
          Premix,Instant Drinks,Ice Tea Premix.Instant Chutneys,Tea
          Premixes,Coffee Premixes, Instant Cake Mixes, dairy mixes, dhokla mixes,
          idli mixes, Gulab jamun mixes,etc.These products have found increasing
          favour in the recent years for the modern women. The market for instant
          food is steadily growing, especially among urban consumers with a host
          of competing brands and flavours.
        </p>
      </div>
    </div>
  </ng-container>
  <!--  -->
  <ng-container *ngIf="category === 'Frozen-Products'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/frozen.jpg" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p>
          Frozen fruits and vegetables, Frozen RTE, Frozen Bakery Products, Frozen
          Meat and Fish are some of the main categories in frozen food segment.
          The size of the Indian frozen food market, which peaked in 2022 at INR
          144.3 billion, is projected to rise to INR 353.3 billion by 2028, with a
          compound annual growth rate (CAGR) of 16% from 2023 to 2028.Several
          businesses are taking strategic actions, such as introducing novel
          frozen-ready meal goods and buying rival businesses to increase their
          market share. As a result, the market for frozen prepared meals is
          expanding quickly. With increasing availability of cold chain in Tire II
          and Tire III cities along with increased inclination towards convenience
          and improved spending power of middle and lower middle class, Indian
          market for these food segments is expected to witness double digit
          growth in coming years.
        </p>
      </div>
    </div>
  </ng-container>
  <!--  -->
  <ng-container *ngIf="category === 'Beverages-&-Packaged-drinking-water'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/Beverages.jpg" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p>
          India's bottled water market size was valued at $22.72 billion in 2022
          and is expected to reach $36.21 billion by 2030, at a CAGR of 6 per cent
          from 2023 to 2030, said a report by Grey Views Research released in
          January 2023. 
        </p>
      </div>
    </div>
  </ng-container>
  <!--  -->
  <!--  -->
  <ng-container *ngIf="category === 'Spices-&-Condiments'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/Salts, spices, soups, sauces, salads and protein products.jpg"
          alt="Image Description" class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify">
        <p>
          India is the world's largest producer, consumer and exporter of spices;
          the country produces about 75 of the 109 varieties listed by the
          International Organization for Standardisation (ISO) and accounts for
          half of the global trading in spices. India spices are popular around
          the world since ancient times due to their authentic flavour and
          texture. They are famous for their aroma and taste which enhance food
          quality. At present, the rising demand for blended spices due to the
          hectic lifestyle and busy schedules of individuals in the country
          represents one of the key factors supporting the growth of the market in
          India. The India spices market size reached INR 160,676 Crores in 2022.
          Looking forward, the publisher expects the market to reach INR 298,909
          Crores by 2028, exhibiting a CAGR of 10.9% during 2022-2028. The market
          is experiencing growth due to several factors, including increasing
          demand from foreign countries for authentic Indian spices, advancements
          in technology enhancing production and distribution processes, and
          rising investments in the industry. At present, the rising demand for
          blended spices due to the hectic lifestyle and busy schedules of
          individuals in the country represents one of the key factors supporting
          the growth of the market in India.
        </p>
      </div>
    </div>
  </ng-container>
  <!--  -->
  <!--  -->
  <ng-container *ngIf="category === 'Bakery-products'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/bakery.jpg" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p><strong>Key Points are: </strong></p>
        <strong> <i class='bx bxs-circle mx-2'></i>Bread<br />
          <i class='bx bxs-circle mx-2'></i>Fine bakery wares<br /></strong>
        <p>
          The biscuits and cookies industry in India accounts for nearly 72% of
          the sales in the Indian bakery market. The Indian bakery market stood
          at a value of approximately USD 10.1 billion in 2022. The market is
          further estimated to grow at a CAGR of 9.6% between 2023 and 2028 to
          reach a value of USD 17.44 billion by 2028. The Indian bakery market
          is being supported by the thriving biscuits and cookies sector in the
          region. 
        </p>
      </div>
    </div>
  </ng-container>
  <!--  -->
  <!--  -->
  <ng-container *ngIf="category === 'Sweeteners'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/Sweetners.jpg" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p><strong>Key Points are: </strong></p>
        <strong> <i class='bx bxs-circle mx-2'></i>Refined and raw sugars<br />
          <i class='bx bxs-circle mx-2'></i>Refined and raw sugars<br />
          <i class='bx bxs-circle mx-2'></i>Honey<br /></strong>
        <p>
          The India Food Sweetener Market size is expected to grow from USD
          3,112.77 million in 2023 to USD 3,610.30 million by 2028, at a CAGR of
          3.01% during the forecast period (2023-2028). The growing preference
          for low-sugar or no-calorie diets due to the increasing prevalence of
          obesity and diabetes drives the demand for natural sweeteners
          globally. Foods high in sugar and carbs are referred to as
          confections. Chocolates, biscuits, bars, candies, mints, and other
          confections are among the items available. The behaviors, interests,
          and preferences of consumers are always changing. This has resulted in
          confectionary innovation, which is driving market expansion.
        </p>
      </div>
    </div>
  </ng-container>
  <!--  -->
  <ng-container *ngIf="category === 'Fish-products'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/fish.jpg" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p><strong>Key Points are: </strong></p>
        <strong> <i class='bx bxs-circle mx-2'></i>Fresh fish and fish
          products<br />
          <i class='bx bxs-circle mx-2'></i>Processed fish and fish
          products<br />
          <i class='bx bxs-circle mx-2'></i>Semi-preserved fish and fish
          products<br />
          <i class='bx bxs-circle mx-2'></i>Fully preserved<br /></strong>
      </div>
    </div>
  </ng-container>
  <!--  -->
  <ng-container *ngIf="category === 'Eggs-products'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/eggs.jpg" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p><strong>Key Points are: </strong></p>
        <strong> <i class='bx bxs-circle mx-2'></i>Fresh eggs<br />
          <i class='bx bxs-circle mx-2'></i> Egg products<br />
          <i class='bx bxs-circle mx-2'></i> Preserved eggs<br /></strong>
      </div>
    </div>
  </ng-container>
  <!--  -->
  <ng-container *ngIf="category === 'Snacks-products'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/INDIAN SNACKS & SAVOURIES PRODUCTS.jpg" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
          <p><strong>Key Points are: </strong><br />
          <i class='bx bxs-circle mx-2'></i>SNACKS & SAVOURIES PRODUCTS
        </p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="category === 'Fats-&-oils'">
    <div class="flex flex-col md:flex-row space-x-8">
      <div class="col-md-4 sm:w-full">
        <img src="../../../assets/sector/oil processing.png" alt="Image Description"
          class="max-w-full h-auto mt-3.5 rounded-lg animate__animated animate__zoomIn" />
      </div>
      <div class="col-md-8 sm:w-full">
        <p class="text-justify animate__animated animate__slideInRight">
        <p><strong>Key Points are: </strong></p>
        <strong> <i class='bx bxs-circle mx-2'></i>Fats and oils essentially
          free from water<br />
          <i class='bx bxs-circle mx-2'></i> Fat emulsions <br />
          <i class='bx bxs-circle mx-2'></i>Fat-based desserts<br /></strong>
      </div>
    </div>
  </ng-container>
</div>