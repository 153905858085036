<div class="container-fluid">
  <div class="row">
    <div class="col-sm-3">
      <div class="lftcolumn animate__animated animate__zoomInUp">
        <ul>
          <li><a (click)="navigateTo('../turnkeyProjectSetup/turnkeyProjectSetup')">Turnkey Project Setup</a></li>
          <li><a (click)="navigateTo('../newProductDevelopment')">New Product Development</a></li>
          <li><a (click)="navigateTo('../licensingRegulatoryServices/mandatory/FSSAI')">Licensing & Regulatory Services</a></li>
          <li><a (click)="navigateTo('../financialServices/DPR')">Financial Services</a></li>
          <li><a (click)="navigateTo('../technicalServices')">It Enabled Food Industry</a></li>
          <li><a (click)="navigateTo('../governmentSchemas/MoFPI')">Government Schemes & Subsidy</a></li>
          <li><a (click)="navigateTo('../auditingTrainingServices/Food-Safety-Audits')">Auditing & Training Services</a></li>
          <li><a (click)="navigateTo('../valueAddedService/Monthly-Advisory-Services')">Value Added Services</a></li>
        </ul>
      </div>
    </div>

    <div
      *ngIf="selectedItem === 'DPR' || selectedItem === 'Feasibility-Study-Report' || selectedItem === 'Techno-Economic-Viability-Study-Report'"
      class="col-sm-9">

      <div class="TS-hero animate__animated animate__zoomIn">
        <img class="about_web" src="../../../assets/Services-Images/Feasibility-Study-Optimized.jpg"
          alt="Web Background" />
        <div class="overlay-container  "></div>
      </div>

      <div class="container">
        <div class="inner-block">
          <div class="row align-items-center">
            <div class="col-md-8">
              <div class="TS-title">
                <div class="aos-init aos-animate">
                  <h1 class="TS-title animate__animated animate__fadeInLeftBig">
                    <strong>{{ selectedItem }}</strong>
                  </h1>
                </div>
              </div>
                 <div class="animate__animated animate__slideInLeft">
                <ng-container *ngIf="selectedItem === 'DPR'">
                <i class='bx bxs-hand-right'></i>
                  <p>Ideation and nuturing the Product</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Survellence and Raw material data hunting</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Assistance in selecting the product</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Capacity finalization & Budgetory estmation of project</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Value Engineering</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Design Engineering</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Machinery specification assistance</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Production process Roadmap</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Machineries ,Utilities Financials</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Market Entry Strategy</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Market Linkage</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Human Resource Calculation</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Business Financial</p>
                </ng-container>
                <ng-container *ngIf="selectedItem === 'Feasibility-Study-Report'">
                <i class='bx bxs-hand-right'></i>
                  <p>Support in Estimating total project cost</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Guidance in Finalizing finance</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Analysing Financial Viability</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Backing in competitors study.</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Exploring New Opportunities for Products</p>
                </ng-container>
                <ng-container *ngIf="selectedItem === 'Techno-Economic-Viability-Study-Report'">
                <i class='bx bxs-hand-right'></i>
                  <p>Identification of the risk associated with product.</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Forming risk mitigation strategies.</p>
                <i class='bx bxs-hand-right'></i>
                  <p>Evaluating the financial risks.</p>
                </ng-container>
              </div>
            </div>
            <!-- <div class="col-md-4"> -->
              <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</div>