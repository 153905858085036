import { Component } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent {
  testimonials = [
    {
      img: 'assets/about/Gajanansir.jpg',
      text: 'He is Managing Director of incessantly growing "Food Cognics lndia Pvt. Ltd. Pune“. "Food Cognics lndia Pvt. Ltd. Pune“ is one of the leading food consultancy services in India. Experienced Food Industry Professional with over 15 years of expertise in conceiving, planning, and executing turnkey projects within the food sector...',
      name: 'Mr.Gajanan Kalyane',
      role: 'Director',
      education: 'B.Tech(Food Technology)& MBA (Finance)',
      linkdinLink:'https://www.linkedin.com/in/gajanankalyane/'
    },
    {
      img: 'assets/about/rohan sir.jpg',
      text: "Experienced General Manager with a proven track record of success in overseeing and optimizing operations. With 8+ years in leadership roles, He've consistently delivered results by streamlining processes, improving team performance, and driving profitability. His expertise spans various industries, from hospitality to manufacturing, and he expertise in strategic thinking, team leadership, and exceptional problem-solving skills. Young and dynamic entrepreneur ,Pursuing career in Food Processing Industries mainly in to business development, Worked for New Territory Development for Food FMCG Products like Cocoa Based Chocolates, Frozen RTE, wafers and namkeens. Effective lead generation through social media and offline channels.",
      name: 'Mr.Rohan Bhole',
      role: 'General Manager',
      education: 'MBA (Sales & Marketing)',
      linkdinLink: 'https://www.linkedin.com/in/rohan-bhole-a5235510a'
    },
    {
      img: 'assets/teams/teams.jpg',
      text: 'She is has expertise in the field of food science and technology. She is hardworking individual who is always eager to learn new skills. With opportunity to work on diverse projects from product develpoment to regulatory complience. Hand on Research and Development alspo ISO, HACCP certified , she strive for perfection.Have good leadership qualities and good communication skills.',
      name: 'Durva Yadav',
      role: 'Regulatory Executive',
      education:'M.Tech (Food Technology)',
      linkdinLink: 'https://www.linkedin.com/in/durva-yadav-0467001a7'
    },
    {
      img: 'assets/about/dhnanjaysir.jpeg',
      text: "Passionate and results-driven professional with a background in agriculture and a Master's in Business Administration specializing in Sales and Marketing. He bring a unique blend of agricultural expertise and strategic marketing skills to the table. With a deep understanding of the food industry and a track record of driving sales and brand growth.He keeps ability to bridge the gap between the farm and market, combined with innovative marketing strategies.",
      name: 'Dhananjay Gadekar',
      role: 'Project Coordinator',
      education: 'MBA(Agri.& Food Busniess Management)',
       linkdinLink: 'https://www.linkedin.com/in/dhananjay-gadekar-315b05221'
    },
    {
      img:  'assets/about/dattasir.jpeg',
      text: 'With a strong foundation rooted in agriculture from his home background, he bring a unique blend of practical experience and academic expertise to the table. He hold a B.Tech in Food Technology and have further honed his skills with an MBA in Food and Agri Business Management. His professional journey has equipped him with a deep understanding of marketing and sales strategies in the agri-food sector. He is dedicated to contributing valuable insights and driving success in the dynamic intersection of agriculture and business.',
      name:  'Datta Malewar',
      role: 'Business Development Excecutive',
      education: 'MBA (Agri & Food Business Management)',
      linkdinLink: 'https://www.linkedin.com/in/datta-malewar-733903171/'
    },
    {
      img:   'assets/about/mr.amol.jpg',
      text: "With over 18 years of experience in the dairy industry, he had successfully managed and executed numerous turnkey projects related to dairy production and processing. His expertise encompasses the entire spectrum of dairy project setup, from initial concept and planning to implementation and operation.He has a deep understanding of dairy equipment, technology, and quality standards. His track record includes optimizing production processes, ensuring compliance with regulatory requirements, and achieving cost-efficiency.He has also involved in team leadership and project management, demonstrating the ability to coordinate and oversee complex projects within the dairy sector",
      name:  'Mr.Amol Ghodke',
      role: 'Dairy Expert',
      education: 'Dairy technology ',
     linkdinLink: 'https://www.linkedin.com/in/amol-ghodke/'
    },
    
  ];
}
