<section id="hero" class="hero section" (click)="scrollToTop()">
  <img src="../../assets/about/Aboutimgs.png" alt="" data-aos="fade-in">
</section>
<!--  -->
<section class="about section-padding bg-white">
  <div class="container con">
    <div class="row align-items-center paraLeft">
      <div class="col-md-6 animate__animated animate__slideInLeft">
        <div class="sub-title border-bot-light">Welcome to</div>
        <div class="section-title animate__animated animate__slideInDown">FoodCognics</div>
        <p>
          Embracing a comprehensive approach, FoodCognics delivers an all-encompassing solution addressing the
          multifaceted challenges in
          the current food industry landscape. Our 12-month handholding program is tailored for aspiring food startups,
          guiding them from
          conceptualization to realization.
        </p>
        <p>
          We foster and transform ideas into scalable, lucrative ventures, offering a seamless journey through services
          like product
          ideation, business plan development, feasibility research, product formulation, contract manufacturing setup,
          project management,
          regulatory compliance, vendor coordination, and client satisfaction.
        </p>
        <p>
          Our design integrates cutting-edge food safety standards and the latest technological advancements in
          flooring, drainage,
          processing machinery, utilities, and more, positioning us as your dedicated in-house project team.
        </p>
      </div>
      <div class="image1 col-md-6 sm:w-full">
        <img src="assets/about/Ongoing Orojects FoodCognics.png" alt="Image Description"
          class="rounded-lg animate__animated animate__zoomIn" />
      </div>
    </div>
  </div>
</section>

<!-- our people -->

<app-testimonials></app-testimonials>
<!--  -->
<section class="amenities section-padding">
  <div class="container">
    <div class="row mb-30">
      <div class="col-md-3">
        <div class="sub-title border-bot-light" id="subtitle1">Our Team</div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="teams mb-4">
          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/Teams-Icons/Regulatory Experts FoodCognics.jpg" alt="" />
            </div>
            <div class="content">
              <h2>Regulatory Expert</h2>
              <p>
                Strongly reliable and focused food regulatory affairs specialist with great depth and breadth of
                experience in licensing
                ,renovation of license, solving the queries and provide integrated and excellent solutions to
                businesses.
              </p>
            </div>
          </div>

          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/Teams-Icons/Food technologist.jpg" alt="" />
            </div>
            <div class="content">
              <h2>Sr. Food Technologist</h2>
              <p>
                We have more than 15-20 year experience food technologist .That will continuously work on the
                development and manufacturing
                processes and recipes of food and drink products. The aim will be to make sure the products are safe,
                made in an efficient
                way and meet specific standards. They could also work on existing and newly-discovered ingredients to
                invent new recipes and
                concepts.
              </p>
            </div>
          </div>
          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/teams/3.jpg" alt="" />
            </div>
            <div class="content">
              <h2>Mechanical Engineers</h2>
              <p>
                Highly skilled and experienced mechanical engineers with expertise in product development, testing, and
                engineering.
                Demonstrated success in managing the whole process of developing multiple new product lines from
                conception to market
                release. Exceptionally skilled in creating detailed 3D models and drawings, as well as designing and
                testing various types
                of mechanical equipment/tools.
              </p>
            </div>
          </div>
        </div>
        <div class="teams mb-4">
          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/Teams-Icons/Architectural Engg.by FoodCognics .jpg" alt="" />
            </div>
            <div class="content">
              <h2>Industrial Civil Engineers & Architect</h2>
              <p>
                The team of dedicated and experienced civil and electrical engineers with extensive knowledge of
                engineering, practical
                experience ,principles, theories, specifications and standards. They have proven track record of
                finishing complex project
                under budget and ahead of schedules.
              </p>
            </div>
          </div>

          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/Teams-Icons/Architectural Engg.by FoodCognics .jpg" alt="" />
            </div>
            <div class="content">
              <h2>Industrial Civil Engineers & Architect</h2>
              <p>
                The team of dedicated and experienced civil and electrical engineers with extensive knowledge of
                engineering, practical
                experience ,principles, theories, specifications and standards. They have proven track record of
                finishing complex project
                under budget and ahead of schedules.
              </p>
            </div>
          </div>

          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/Teams-Icons/CAD CAM Designer FoodCognics.jpg" alt="" />
            </div>
            <div class="content">
              <h2>CAD/CAM Designer.</h2>
              <p>
                We have deadline-oriented CAD/CAM Designer driven to exceed objectives by proficiently overseeing teams
                and projects in
                fast-paced, high volume settings. They have experience in Operations, Programming, Design, Fabrication,
                Integration, and
                Installation
              </p>
            </div>
          </div>
        </div>
        <div class="teams mb-4">
          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/teams/7.png" alt="" />
            </div>
            <div class="content">
              <h2>Refrigeration Engineers</h2>
              <p>
                Results-driven Refrigeration Engineer with excellent experience in designing, installing, and
                maintaining cooling systems.
                Proficient: in troubleshooting and optimizing refrigeration equipment for peak performance, achieving
                energy efficiency, and
                ensuring regulatory compliance,
              </p>
            </div>
          </div>

          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/Teams-Icons/MEP.jpg" alt="" />
            </div>
            <div class="content">
              <h2>MEP Designer</h2>
              <p>
                The MEP team are efficient and experienced. Plumber skilled in PEX, copper installation, repairs and
                maintenance. Electrical
                engineers use the principles of electricity to develop noval solutions to the problems
              </p>
            </div>
          </div>
          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full mt-2" src="assets/teams/9.jpg" alt="" />
            </div>
            <div class="content">
              <h2>Product development Expert</h2>
              <p>
                Resourceful and passionate Food Technologist with expertise in the field of food product development,
                research ,production
                and sensory evaluation.
              </p>
            </div>
          </div>
        </div>
        <!-- 4  -->
        <div class="teams mb-4">
          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full mt-4 sm:mt-0" src="assets/teams/9.jpg" alt="" />
            </div>
            <div class="content">
              <h2>Agri Business Management</h2>
              <p>
                Diligent Agribusiness Management team who oversees business operations for farms and other local
                agriculture companies.
                Adopt at developing marketing strategies and provide valuable guidance. Proficient in agricultural
                technology, current
                trends and market scenario. Provide integrated solution to farm practices and increasing the farm
                income.
              </p>
            </div>
          </div>

          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/Teams-Icons/Graphic Designer by FoodCognics.jpg" alt="" />
            </div>
            <div class="content">
              <h2>Graphic Designer</h2>
              <p>
                With Creative UX, Graphics Designer with excellent experience in optimizing user experience through
                innovative solutions and
                dynamic interface designs. We Successfully in enhance user engagement for well-known brands, providing a
                compelling user
                experience to improve brand loyalty and customer retention.
              </p>
            </div>
          </div>

          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full mt-4" src="assets/teams/10.jpg" alt="" />
            </div>
            <div class="content">
              <h2>Agri Economist</h2>
              <p>
                Agricultural economist with experience in research, policy analysis, and consulting. Proven ability to
                synthesize data into
                clear and concise insights for both technical and non-technical audiences.
              </p>
            </div>
          </div>
        </div>
        <!-- 5 -->
        <div class="teams mb-4">
          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/Teams-Icons/Chartered Accountant By FoodCognics.jpg" alt="" />
            </div>
            <div class="content">
              <h2>Chartered Accountant (CA)</h2>
              <p>
                A Chartered Accountant (CA) is a highly qualified professional with expertise in accounting, audit, and
                financial
                management. Our CAs adhere to a strict code of ethics, providing crucial services such as auditing
                financial statements,
                offering tax advice, and contributing to sound financial decision-making for businesses.
              </p>
            </div>
          </div>
          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/Teams-Icons/Energy Auditor FoodCognics.jpg" alt="" />
            </div>
            <div class="content">
              <h2>Energy Auditor</h2>
              <p>
                We have results-driven and seasoned Energy Auditor with over 8 years of hands-on experience in
                evaluating and optimizing
                energy efficiency across diverse food industries. Adept at conducting comprehensive energy audits,
                analyzing data, and
                recommending cost-effective solutions to enhance sustainability and reduce environmental impact. Proven
                track record of
                achieving significant energy savings for clients through innovative strategies and technologies.
              </p>
            </div>
          </div>
          <div class="testimonial-card" data-aos="fade-up">
            <div class="team">
              <img class="rounded-full" src="assets/Teams-Icons/Food Packaging Expert By FoodCognics.jpg" alt="" />
            </div>
            <div class="content">
              <h2>Food Packaging Experts</h2>
              <p>
                Experienced and innovative Food Packaging Expert with a proven track record of over 10 years in the
                industry. Adept at
                developing and implementing cutting-edge packaging solutions that enhance product quality, shelf life,
                and sustainability.
                Specialized in optimizing packaging processes to meet regulatory requirements and improve overall
                efficiency. Our experts
                committed to driving innovation in the food packaging sector through a combination of technical
                expertise and a passion for
                sustainable practices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>