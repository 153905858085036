<!-- test-page.component.html -->
<section id="testimonial_section" class="custom-section-padding staff-augmentation-testimonial py-5 ">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 text-center" data-aos="fade-up">
        <h2 class="font-weight-normal text-6">
          Client <strong class="font-weight-bold">Testimonials</strong>
        </h2>
        <p style="font-style:normal"> 
          Our clients help and strengthen our mission every day by their positive affirmations.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <carousel *ngIf="testimonialsData.length > 0" [interval]="3000" [keyboard]="true">
          <slide *ngFor="let testimonial of testimonialsData">
            <div class="row">
              <div class="col-8 col-sm-4 col-lg-2 text-center pt-5">
                <img class="img-fluid" [src]="testimonial.image" width="166" height="160" alt="" loading="lazy">
              </div>
              <div class="col-12 col-sm-12 col-lg-10">
                <div class="testimonial custom-testimonial-style-1 mb-0">
                  <blockquote class="pb-2">
                    <p>{{ testimonial.content }}</p>
                  </blockquote>
                  <div class="testimonial-author float-start">
                    <p style="font-style:normal;font-family:system-ui;">
                      <strong>{{ testimonial.author }} - </strong>
                      <strong><span class="text-color-primary">{{ testimonial.company }}</span></strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</section>
